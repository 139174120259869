import { GoogleOAuthProvider } from "@react-oauth/google";

interface AuthRouteProps {
  children: JSX.Element;
}

export function AuthSoccial({ children }: AuthRouteProps) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH ?? ""}>
      {children}
    </GoogleOAuthProvider>
  );
}
