import { useMemo } from "react";
import { BiographyContext } from "./biography.context";
import {
  IBiographyContextData,
  IBiographyProvider,
} from "./biography.interface";
import { useListBookData } from "../../../pages/biography/bookData/hooks/useListBookData";
import { useDisclosure } from "@chakra-ui/react";

export const BiographyProvider = ({ children }: IBiographyProvider) => {
  const { dataList: dataMenuBook, refetch: getMenuBook } = useListBookData();

  const {
    isOpen: isOpenDraw,
    onOpen: openDraw,
    onClose: onCloseDraw,
  } = useDisclosure();

  const values: IBiographyContextData = useMemo(() => {
    return { dataMenuBook, getMenuBook, isOpenDraw, openDraw, onCloseDraw };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMenuBook, isOpenDraw]);

  return (
    <BiographyContext.Provider value={values}>
      {children}
    </BiographyContext.Provider>
  );
};
