import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiArrowLeftCircleFill } from "react-icons/ri";

type Props = {
  title: string;
  isIcon?: boolean;
  isDark?: boolean;
};
export function Title({ title, isIcon = true, isDark = true }: Props) {
  return (
    <Flex style={{ alignItems: "center" }}>
      {isIcon && (
        <Icon
          fontSize={["16px", "16px", "24px"]}
          as={RiArrowLeftCircleFill}
          color={isDark ? "#1E1E1E" : "#1E1E1E"}
        />
      )}
      <Text
        style={{
          color: isDark ? "#1E1E1E" : "#1E1E1E",
          fontWeight: isDark ? 600 : 500,
          marginLeft: 13,
        }}
        fontSize={["16px", "16px", "24px"]}
      >
        {title}
      </Text>
    </Flex>
  );
}
