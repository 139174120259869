import {
  Box,
  Button,
  Flex,
  Image,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ContainerSmall } from "../../components/ContainerSmall";
import { useBiographyShow } from "../biographyShow/hooks/useBiographyShow";
import { useState } from "react";
import { ICopyData, copyData } from "../../data/data";
import { CardItemLine } from "./components/cardItemLine";
import { numberFormart } from "../../utils/formatters";
import { api } from "../../service/api";
import { BOOK_PREVIEW } from "../../assets";

export function PhysicalBook() {
  let { id } = useParams();
  const toast = useToast();

  const { dataList, loading, refetch } = useBiographyShow(id as string);

  const [copySelected, setCopySelected] = useState("");
  const [copyValue, setCopyValue] = useState<ICopyData | null>();

  const [loadingSend, setLoadingSend] = useState(false);

  async function createOrder() {
    try {
      setLoadingSend(true);

      const value = copyData.find((item) => item.id === copySelected);

      if (!value) return;

      await api.post("/book/order/create", {
        biography_id: id,
        copies: value.quantityCopy,
        price: value.price,
        delivery_time: value.delivery_time,
      });
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Pedido enviado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      refetch();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Não foi possível finalizar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSend(false);
    }
  }

  return (
    <ContainerSmall>
      <Flex flexDirection="column" width="100%">
        {!loading && dataList && (
          <Flex
            w="100%"
            marginTop="50px"
            marginBottom="50px"
            flexDirection="column"
          >
            <Text
              color="#181818"
              textAlign={["center", "center", "start"]}
              fontWeight={500}
              fontSize="28px"
            >
              Imprimir Livro Físico
            </Text>

            <Flex
              mt="50px"
              gap="40px"
              flexDirection={["column", "column", "row"]}
              textAlign={["center", "center", "start"]}
            >
              <Flex
                width="216px"
                height="304px"
                borderWidth="1px"
                borderColor="#36A9E1"
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                backgroundColor="#36A9E1"
                position="relative"
                margin={["auto", "auto", "inherit"]}
              >
                <Image
                  src={BOOK_PREVIEW}
                  alt={"book"}
                  width="216px"
                  height="304px"
                  borderRadius="10px"
                />
                {dataList.book && (
                  <Image
                    src={dataList.book.path}
                    alt={dataList.book.title}
                    width="216px"
                    height="304px"
                    borderRadius="10px"
                  />
                )}

                {!dataList.book && (
                  <Text
                    position="absolute"
                    color="#000000"
                    fontSize="22px"
                    textAlign="center"
                    fontWeight={500}
                  >
                    {dataList.title}
                  </Text>
                )}
              </Flex>

              <Flex flexDirection="column" width={["100%", "100%", "400px"]}>
                <Text color="#181818" fontWeight={500} fontSize="28px">
                  {dataList.title}
                </Text>
                <Text
                  mt="10px"
                  color="#181818"
                  fontWeight={400}
                  fontSize="16px"
                >
                  Selecione a quantidade de cópias
                </Text>

                {dataList && !dataList.book_order && (
                  <>
                    <Flex>
                      <Select
                        size="lg"
                        mt="25px"
                        fontSize="16px"
                        color="#181818"
                        fontWeight={500}
                        borderColor="#9D9D9D"
                        height="50px"
                        borderRadius="100px"
                        onChange={(option: any) => {
                          const idValue = option.target.value;
                          setCopySelected(idValue);
                          const value = copyData.find(
                            (item) => item.id === idValue
                          );
                          if (value) {
                            setCopyValue(value);
                          }
                        }}
                        value={copySelected}
                        placeholder="Selecione as cópias"
                      >
                        {copyData.map((item, _) => (
                          <option
                            value={item.id}
                            selected={copySelected === item.id}
                          >
                            {`${item.quantityCopy} cópias`}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                    <Flex mt="30px" gap="20px" flexDirection="column">
                      {copyValue && (
                        <>
                          <CardItemLine
                            title="numero de cópias"
                            value={`${copyValue.quantityCopy} cópias`}
                          />
                          <CardItemLine
                            title="valor"
                            value={`R$ ${numberFormart(copyValue.price)}`}
                          />
                          <CardItemLine
                            title="prazo"
                            value={`${copyValue.delivery_time} dias`}
                          />

                          <Box>
                            <Button
                              isLoading={loadingSend}
                              type="button"
                              bg="blue.400"
                              color="white"
                              style={{
                                borderRadius: "50px",
                                height: 37,
                                fontSize: 16,
                                fontWeight: 600,
                              }}
                              _hover={{
                                bg: "blue.400",
                              }}
                              mt="6"
                              size="lg"
                              onClick={() => {
                                createOrder();
                              }}
                            >
                              Finalizar Solicitação
                            </Button>
                          </Box>
                        </>
                      )}
                    </Flex>
                  </>
                )}

                {dataList && dataList.book_order && (
                  <Flex mt="60px" flexDirection="column" gap="5px">
                    <Text>status:</Text>
                    <Text color="#42AD4D" fontWeight="bold">
                      solicitação em andamento
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </ContainerSmall>
  );
}
