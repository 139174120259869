import { Box, Flex } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { BiographySidebar } from "../components/sidebar";
import { BaseInformationHeader } from "./components/header";
import { BaseInformationMenu } from "./components/menu";
import { IntroductionBaseInformation } from "./introduction";
import { typesBaseInformation } from "../../../data/data";

export function BaseInformation() {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("type") ?? "";

  return (
    <Flex width="100%" overflowX="hidden">
      <BiographySidebar id={id as string} type="data" />
      <Box
        width="100%"
        mt={["60px", "40px", "0px"]}
        px={["0px", "0px", "40px"]}
        py={["0px", "0px", "60px"]}
      >
        <BaseInformationHeader />
        <Flex mt="40px" gap="30px">
          <BaseInformationMenu
            id={id as string}
            pageType={pageType}
            title={
              typesBaseInformation.find((it) => it.type === pageType)?.title
            }
          />
          <Flex width="100%">
            {typesBaseInformation.map((item, _) => {
              if (item.type === pageType) {
                return (
                  <IntroductionBaseInformation
                    key={item.id}
                    id={id as string}
                    type={pageType}
                    title={item.title}
                    description={item.description}
                    locality={item.locality}
                  />
                );
              }
              return null;
            })}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
