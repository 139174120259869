import { Flex } from "@chakra-ui/react";
import { Title } from "../../components/Title";

import { useListPlan } from "./hooks/useListPlan";
import { FullLoading } from "../../components/fullLoading";
import { ContainerSmall } from "../../components/ContainerSmall";
import { CardPlan2 } from "./components/cardPlan2";

export function Plan() {
  const { dataList, loading } = useListPlan();

  return (
    <>
      {loading && <FullLoading />}
      {!loading && dataList && (
        <ContainerSmall>
          <Flex
            width={["auto", "auto", "100%"]}
            marginTop="41px"
            marginBottom="50px"
            flexDirection="column"
          >
            <Title title="Planos" isDark={false} isIcon={false} />

            <Flex mt="20px" gap="20px">
              {dataList.map((item, _) => (
                <CardPlan2 key={item.id} plans={item} />
              ))}
            </Flex>
          </Flex>
        </ContainerSmall>
      )}
    </>
  );
}
