import { Button, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { InputTextArea } from "../../../../../../../../components/form/textarea";

type Props = {
  onSend: (text: string) => void;
};

export function DescriptionAudio({ onSend }: Props) {
  const [text, setText] = useState("");

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <InputTextArea
        name="descrition"
        label="Comentário"
        placeholder="Comentário..."
        size="lg"
        value={text}
        onChange={(txt) => setText(txt.target.value)}
      />
      <Button
        type="button"
        bg="blue.400"
        color="white"
        style={{
          borderRadius: 10,
          height: 44,
          fontSize: 16,
          fontWeight: 600,
        }}
        _hover={{
          bg: "blue.400",
        }}
        size="lg"
        onClick={() => {
          onSend(text);
        }}
      >
        Enviar
      </Button>
    </Flex>
  );
}
