export type dataQuestionsProps = {
  id: string;
  type: string;
  questions: questionsType[];
};

export type questionsType = {
  id: string;
  description: string;
};

export const dataQuestionsMe: dataQuestionsProps[] = [
  {
    id: "1",
    type: "infancy",
    questions: [
      {
        id: "1",
        description: "Quando e onde você nasceu?",
      },
      {
        id: "2",
        description:
          "Você se lembra de alguma história interessante sobre o seu nascimento?",
      },
      {
        id: "3",
        description: "Qual é sua primeira memória?",
      },
      {
        id: "4",
        description: "Quem foi a pessoa mais influente para você na infância?",
      },
      {
        id: "5",
        description:
          "Que adjetivos você usaria para descrever sua infância? Por quê?",
      },
      {
        id: "6",
        description:
          "Como seus pais descreveriam você quando criança? Por quê?",
      },
      {
        id: "7",
        description:
          "Você tinha algum animal de estimação quando criança? Que tipo?",
      },
      {
        id: "8",
        description: "O que você fazia durante as férias de verão?",
      },
      {
        id: "9",
        description: "Quais eram os seus jogos ou esportes favoritos?",
      },
      {
        id: "10",
        description:
          "Quem foi seu primeiro melhor amigo? Você tinha outros amigos com quem convivia com frequência?",
      },
      {
        id: "11",
        description:
          "Você tinha algum apelido? Elabore sobre ele e sobre os apelidos dentro da família.",
      },
      {
        id: "12",
        description: "Do que você mais tinha medo quando criança?",
      },
      {
        id: "13",
        description:
          "Você se lembra de alguma história interessante contada por algum parente mais velho que você nunca esqueceu e que acha que vale a pena contar?",
      },
      {
        id: "14",
        description:
          "Fale sobre seus irmãos, primos e outros parentes próximos, e como era seu relacionamento com eles.",
      },
      {
        id: "15",
        description: "Descreva seus pais. Como eles eram? O que eles faziam?",
      },
      {
        id: "16",
        description:
          "Onde seu pai trabalhava? Qual é a imagem mais forte que você tem dele?",
      },
      {
        id: "17",
        description:
          "Onde sua mãe trabalhava? Qual é a imagem mais forte que você tem dela?",
      },
      {
        id: "18",
        description: "Descreva seus avós. O que você mais gostava neles?",
      },
      {
        id: "19",
        description:
          "Você viveu em mais de uma casa ou cidade? Quais memórias são marcantes desses lugares?",
      },
      {
        id: "20",
        description:
          "Como você viveu a sua infância no bairro em que cresceu? Quais brincadeiras coloriam seu dia a dia?",
      },
    ],
  },
  {
    id: "2",
    type: "family",
    questions: [
      {
        id: "1",
        description:
          "Você tem filhos? Se sim, quantos e quais são as idades e sexos?",
      },
      {
        id: "2",
        description: "Qual é o seu objetivo como pai ou mãe?",
      },
      {
        id: "3",
        description: "O que a palavra 'família' significa para você?",
      },
      {
        id: "4",
        description:
          "Você tem algum arrependimento em relação à forma como criou seus filhos?",
      },
      {
        id: "5",
        description: "De que maneira você influenciou mais seus filhos?",
      },
      {
        id: "6",
        description: "Como seus filhos descreveriam você como pai ou mãe?",
      },
      {
        id: "7",
        description: "Como você se descreve como pai ou mãe?",
      },
      {
        id: "8",
        description: "De que maneiras seus pais te influenciaram mais?",
      },
      {
        id: "9",
        description: "Com qual dos seus pais você mais se parece e por quê?",
      },
      {
        id: "10",
        description: "Você gostaria de ter tido uma criação diferente? Como?",
      },
      {
        id: "11",
        description: "Como é seu relacionamento com seus pais hoje?",
      },
      {
        id: "12",
        description: "Qual é o seu papel na família?",
      },
      {
        id: "13",
        description:
          "De que forma a maneira como você foi criado(a) afetou a forma como você criou seus próprios filhos?",
      },
      {
        id: "14",
        description:
          "Você tem irmãos? Quantos, eles são mais velhos ou mais novos, e quais são os sexos?",
      },
      {
        id: "15",
        description: "Como são seus relacionamentos com seus irmãos?",
      },
      {
        id: "16",
        description:
          "Você mudaria algo em relação aos seus relacionamentos com eles?",
      },
      {
        id: "17",
        description: "Quem é a pessoa mais próxima de você na sua família?",
      },
      {
        id: "18",
        description: "Quem você mais admira na sua família e por quê?",
      },
      {
        id: "19",
        description:
          "Você foi criado(a) em uma família desfeita? Se sim, por quem você foi criado(a) e como isso o(a) afetou?",
      },
      {
        id: "20",
        description:
          "Você perdeu algum membro da família por morte inesperada? Se sim, qual era o seu relacionamento com eles e como a morte deles o(a)/sua família afetou?",
      },
      {
        id: "21",
        description:
          "Sua família tinha algumas tradições únicas? De que forma isso contribuiu com a sua memória afetiva?",
      },
      {
        id: "22",
        description:
          "Você e sua família dividem alguma piada interna ou lembrança divertida? Quais?",
      },
    ],
  },
  {
    id: "3",
    type: "memories-of-school-and-high-school",
    questions: [
      {
        id: "1",
        description:
          "Onde você estudou primeiro? Ainda guarda lembranças do lugar e de algum professor?",
      },
      {
        id: "2",
        description:
          "Você se lembra do seu período de alfabetização? Seus pais te contaram alguma coisa sobre isso, ou ainda podem contar?",
      },
      {
        id: "3",
        description: "Onde você estudou no ensino médio?",
      },
      {
        id: "4",
        description:
          "Quem era seu melhor amigo? Você ainda mantém contato com ele(a)?",
      },
      {
        id: "5",
        description: "Qual era sua matéria favorita para estudar?",
      },
      {
        id: "6",
        description: "Qual era a matéria que você menos gostava? Por quê?",
      },
      {
        id: "7",
        description: "Você participou de algum time esportivo?",
      },
      {
        id: "8",
        description: "Qual era seu esporte favorito nesse período?",
      },
      {
        id: "9",
        description:
          "Você participava de algum clube especial dentro da escola?",
      },
      {
        id: "10",
        description: "Você fez parte do grêmio estudantil?",
      },
      {
        id: "11",
        description:
          "Algum professor foi particularmente influente para você? Por quê?",
      },
      {
        id: "12",
        description:
          "Como você se descreveria como aluno, tanto socialmente quanto academicamente?",
      },
      {
        id: "13",
        description:
          "O que você mais gostava na escola? E o que menos gostava?",
      },
      {
        id: "14",
        description:
          "Em que tipo de atividades extracurriculares você participou na adolescência?",
      },
      {
        id: "15",
        description:
          "Você ganhou algum prêmio ou foi reconhecido por algum feito?",
      },
      {
        id: "16",
        description:
          "Você tinha algum ídolo adolescente? Se sim, quem era e como ele(a) despertou seu interesse?",
      },
      {
        id: "17",
        description: "Quem foi seu primeiro relacionamento?",
      },
      {
        id: "18",
        description: "Que tipo de música você escutava?",
      },
      {
        id: "19",
        description:
          "Qual era sua música, banda e dança favorita no ensino médio?",
      },
      {
        id: "20",
        description:
          "O que as pessoas que você conhece hoje achariam surpreendente sobre você na adolescência?",
      },
    ],
  },
  {
    id: "4",
    type: "college-and-early-adulthood",
    questions: [
      {
        id: "1",
        description:
          "Você frequentou a faculdade? Se sim, qual? Se não, por quê?",
      },
      {
        id: "2",
        description: "O que te motivava a frequentar a faculdade?",
      },
      {
        id: "3",
        description: "Você pagou seus estudos ou foi apoiado pelos seus pais?",
      },
      {
        id: "4",
        description: "Você morava em um dormitório?",
      },
      {
        id: "5",
        description:
          "Quem foi seu colega de quarto mais memorável? Você ainda tem contato com alguns desses colegas?",
      },
      {
        id: "6",
        description: "Por que você decidiu frequentar a faculdade?",
      },
      {
        id: "7",
        description: "Que matérias você estudou e por quê?",
      },
      {
        id: "8",
        description: "Você se formou? Em quê?",
      },
      {
        id: "9",
        description:
          "Quantas gerações da sua família receberam um diploma antes de você?",
      },
      {
        id: "10",
        description:
          "Sua família apoiou sua decisão de ir para a faculdade? Como?",
      },
      {
        id: "11",
        description:
          "Como sua experiência universitária o preparou para os anos seguintes?",
      },
      {
        id: "12",
        description:
          "Se você pudesse fazer tudo de novo, escolheria um caminho acadêmico diferente ou está satisfeito com a rota que seguiu?",
      },
      {
        id: "13",
        description:
          "De que forma a faculdade moldou seu repertório e seus interesses hoje?",
      },
      {
        id: "14",
        description:
          "Você sempre sonhou em fazer esse curso, ou foi uma decisão que levou tempo até você amadurecer essa ideia?",
      },
      {
        id: "15",
        description:
          "Você fez um diploma avançado? Se sim, qual foi, e você o utilizou em sua carreira profissional?",
      },
      {
        id: "16",
        description: "Em quais atividades no campus você participou?",
      },
    ],
  },
  {
    id: "5",
    type: "career",
    questions: [
      {
        id: "1",
        description: "Qual foi o seu primeiro emprego?",
      },
      {
        id: "2",
        description: "Qual foi o seu melhor emprego?",
      },
      {
        id: "3",
        description: "Qual foi o seu pior emprego?",
      },
      {
        id: "4",
        description:
          "Qual foi o maior tempo que você já permaneceu em uma empresa? Qual era sua posição lá?",
      },
      {
        id: "5",
        description:
          "Além de receber dinheiro, de que outra forma sua carreira agregou valor à sua vida?",
      },
      {
        id: "6",
        description:
          "Você tem algum arrependimento em relação à sua trajetória profissional?",
      },
      {
        id: "7",
        description:
          "Qual é a sua conquista mais significativa como profissional?",
      },
      {
        id: "8",
        description: "Você já foi demitido por razões injustas?",
      },
      {
        id: "9",
        description:
          "O que você considera ser o melhor ambiente de trabalho possível?",
      },
      {
        id: "10",
        description: "Quem foi a maior influência na sua carreira?",
      },
      {
        id: "11",
        description:
          "Quem foi o seu melhor chefe e por que você admirava a sua liderança?",
      },
      {
        id: "12",
        description:
          "Você já liderou uma equipe? Quais foram os maiores desafios e aprendizados?",
      },
      {
        id: "13",
        description: "Qual seria o seu trabalho ideal?",
      },
      {
        id: "14",
        description:
          "Conte aqui dois ou três desafios profissionais que você teve, e como você os solucionou.",
      },
    ],
  },
  {
    id: "6",
    type: "love-life",
    questions: [
      {
        id: "1",
        description: "Quem foi seu primeiro amor?",
      },
      {
        id: "2",
        description: "Você já teve o coração partido?",
      },
      {
        id: "3",
        description: "Você já partiu o coração de alguém?",
      },
      {
        id: "4",
        description: "Você já foi casado(a)? Com quem e por quanto tempo?",
      },
      {
        id: "5",
        description: "Descreva como você é como namorado(a).",
      },
      {
        id: "6",
        description: "Como você e seu cônjuge se conheceram?",
      },
      {
        id: "7",
        description: "Como você propôs e/ou aceitou o pedido de união?",
      },
      {
        id: "8",
        description: "Descreva seu casamento e sua lua de mel.",
      },
      {
        id: "9",
        description: "Você acredita em amor à primeira vista?",
      },
      {
        id: "10",
        description: "Você já se divorciou?",
      },
      {
        id: "11",
        description: "Descreva o seu pior término de relacionamento.",
      },
      {
        id: "12",
        description: "Quem foi a sua maior paixão?",
      },
      {
        id: "13",
        description: "Qual é o seu papel no casamento ou relacionamentos?",
      },
      {
        id: "14",
        description:
          "Você tem algum arrependimento em relação à sua vida amorosa?",
      },
      {
        id: "15",
        description:
          "Qual foi a maneira mais especial que você demonstrou a alguém que o amava?",
      },
      {
        id: "16",
        description:
          "Qual foi a maneira mais especial que alguém demonstrou que te amava?",
      },
    ],
  },
  {
    id: "7",
    type: "political-and-social-causes",
    questions: [
      {
        id: "1",
        description:
          "Qual você considera ser o evento político mais significativo que ocorreu durante sua vida?",
      },
      {
        id: "2",
        description:
          "Qual você considera ser o evento político mais significativo da história da humanidade?",
      },
      {
        id: "3",
        description: "Qual figura política você mais admira? Por quê?",
      },
      {
        id: "4",
        description: "Qual figura política você menos admira? Por quê?",
      },
      {
        id: "5",
        description:
          "Além do presente, em qual era histórica você gostaria de ter vivido?",
      },
      {
        id: "6",
        description:
          "Qual você considera ser a guerra mais importante que foi travada durante sua vida? E em toda a história?",
      },
      {
        id: "7",
        description: "Qual guerra você considera ser a menos importante?",
      },
      {
        id: "8",
        description: "Você já lutou por uma causa política?",
      },
      {
        id: "9",
        description:
          "Quais movimentos sociais afetaram sua consciência social e como?",
      },
      {
        id: "10",
        description:
          "O que você gostaria de mudar no atual cenário político/social?",
      },
      {
        id: "11",
        description: "Você é politicamente ativo(a)?",
      },
      {
        id: "12",
        description:
          "Se tivesse que escolher, por qual causa política/social você daria sua vida?",
      },
      {
        id: "13",
        description:
          "Se pudesse conhecer qualquer ícone histórico, do passado ou do presente, quem seria e por quê?",
      },
    ],
  },
  {
    id: "8",
    type: "general-topics",
    questions: [
      {
        id: "1",
        description: "Qual é a sua definição de 'felicidade'?",
      },
      {
        id: "2",
        description: "Qual foi o seu pior desapontamento?",
      },
      {
        id: "3",
        description: "Qual celebridade você mais gostaria de conhecer?",
      },
      {
        id: "4",
        description: "Qual foi a sua experiência de viagem mais memorável?",
      },
      {
        id: "5",
        description: "Qual é o lugar mais distante de casa que você já esteve?",
      },
      {
        id: "6",
        description:
          "Qual foi a coisa mais engraçada que já aconteceu com você?",
      },
      {
        id: "7",
        description: "Qual foi o seu momento mais rebelde?",
      },
      {
        id: "8",
        description:
          "Se tivesse que escolher, qual é a única coisa de que você se arrepende na maneira como viveu sua vida?",
      },
      {
        id: "9",
        description: "Qual é a sua memória mais feliz?",
      },
      {
        id: "10",
        description: "E a mais triste?",
      },
      {
        id: "11",
        description: "De qual conquista você mais se orgulha?",
      },
      {
        id: "12",
        description: "Se pudesse fazer tudo de novo, o que faria de diferente?",
      },
      {
        id: "13",
        description: "Como espera ser lembrado?",
      },
      {
        id: "14",
        description: "Como acha que as pessoas vão te lembrar?",
      },
      {
        id: "15",
        description: "Quem é o seu maior fã?",
      },
      {
        id: "16",
        description: "De quem você é o maior fã?",
      },
      {
        id: "17",
        description: "O que você gosta de fazer no seu tempo livre?",
      },
      {
        id: "18",
        description:
          "Qual é a única pergunta à qual você sempre responde com uma mentira?",
      },
    ],
  },
];

export const dataQuestionsOthers: dataQuestionsProps[] = [
  {
    id: "1",
    type: "infancy",
    questions: [
      {
        id: "1",
        description: "Quando e onde a pessoa nasceu?",
      },
      {
        id: "2",
        description:
          "A pessoa se lembra de alguma história interessante sobre o seu nascimento?",
      },
      {
        id: "3",
        description: "Qual é a primeira memória dessa pessoa?",
      },
      {
        id: "4",
        description: "Quem foi a pessoa mais influente na infância dela?",
      },
      {
        id: "5",
        description:
          "Que adjetivos poderiam descrever a infância dessa pessoa? Por quê?",
      },
      {
        id: "6",
        description:
          "Como os pais dessa pessoa a descreveriam quando criança? Por quê?",
      },
      {
        id: "7",
        description:
          "Ela tinha algum animal de estimação na infância? Qual tipo?",
      },
      {
        id: "8",
        description: "O que essa pessoa fazia durante as férias de verão?",
      },
      {
        id: "9",
        description: "Quais eram os jogos ou esportes favoritos dela?",
      },
      {
        id: "10",
        description:
          "Quem foi o primeiro melhor amigo dessa pessoa? Ela tinha outros amigos frequentes?",
      },
      {
        id: "11",
        description:
          "Essa pessoa tinha algum apelido? Qual era a história por trás dele e dos apelidos na família?",
      },
      {
        id: "12",
        description: "Do que ela mais tinha medo quando criança?",
      },
      {
        id: "13",
        description:
          "Há alguma história interessante contada por um parente mais velho que essa pessoa nunca esqueceu?",
      },
      {
        id: "14",
        description:
          "Como era o relacionamento dela com irmãos, primos e outros parentes próximos?",
      },
      {
        id: "15",
        description: "Como os pais dessa pessoa eram? O que eles faziam?",
      },
      {
        id: "16",
        description:
          "Onde o pai dessa pessoa trabalhava? Qual é a memória mais forte que ela tem dele?",
      },
      {
        id: "17",
        description:
          "Onde a mãe dessa pessoa trabalhava? Qual é a memória mais forte que ela tem dela?",
      },
      {
        id: "18",
        description:
          "Como eram os avós dessa pessoa? O que ela mais gostava neles?",
      },
      {
        id: "19",
        description:
          "Essa pessoa viveu em mais de uma casa ou cidade? Quais memórias são marcantes desses lugares?",
      },
      {
        id: "20",
        description:
          "Como foi a infância dessa pessoa no bairro onde cresceu? Quais brincadeiras coloriam os dias dela?",
      },
    ],
  },
  {
    id: "2",
    type: "family",
    questions: [
      {
        id: "21",
        description:
          "A pessoa tem filhos? Quantos e quais são as idades e os sexos?",
      },
      {
        id: "22",
        description: "Qual é o objetivo dessa pessoa como pai ou mãe?",
      },
      {
        id: "23",
        description: "O que a palavra 'família' significa para ela?",
      },
      {
        id: "24",
        description:
          "Há arrependimentos em relação à forma como ela criou os filhos?",
      },
      {
        id: "25",
        description: "De que maneira ela influenciou mais os filhos?",
      },
      {
        id: "26",
        description:
          "Como os filhos dessa pessoa a descreveriam como pai ou mãe?",
      },
      {
        id: "27",
        description: "Como ela se descreve como pai ou mãe?",
      },
      {
        id: "28",
        description: "De que formas os pais dessa pessoa a influenciaram mais?",
      },
      {
        id: "29",
        description: "Com qual dos pais ela mais se parece? Por quê?",
      },
      {
        id: "30",
        description:
          "Ela gostaria de ter tido uma criação diferente? Como seria?",
      },
      {
        id: "31",
        description: "Como é o relacionamento atual dela com os pais?",
      },
      {
        id: "32",
        description: "Qual é o papel dessa pessoa na família?",
      },
      {
        id: "33",
        description:
          "De que forma a maneira como ela foi criada influenciou a criação dos próprios filhos?",
      },
      {
        id: "34",
        description:
          "A pessoa tem irmãos? Quantos? Eles são mais velhos ou mais novos? Quais são os sexos?",
      },
      {
        id: "35",
        description: "Como são os relacionamentos dessa pessoa com os irmãos?",
      },
      {
        id: "36",
        description: "Ela mudaria algo nesses relacionamentos?",
      },
      {
        id: "37",
        description: "Quem é a pessoa mais próxima dela na família?",
      },
      {
        id: "38",
        description: "Quem ela mais admira na família? Por quê?",
      },
      {
        id: "39",
        description:
          "A pessoa foi criada em uma família desfeita? Por quem foi criada e como isso a afetou?",
      },
      {
        id: "40",
        description:
          "Ela perdeu algum membro da família inesperadamente? Como isso afetou a pessoa e a família?",
      },
      {
        id: "41",
        description:
          "A família dessa pessoa tinha tradições únicas? Como isso contribuiu para a memória afetiva dela?",
      },
      {
        id: "42",
        description:
          "Há piadas internas ou lembranças divertidas que a pessoa compartilha com a família? Quais?",
      },
    ],
  },
  {
    id: "3",
    type: "memories-of-school-and-high-school",
    questions: [
      {
        id: "43",
        description:
          "Onde essa pessoa estudou primeiro? Ela ainda se lembra do lugar ou de algum professor?",
      },
      {
        id: "44",
        description:
          "Como foi o período de alfabetização dessa pessoa? Há algo que os pais contaram ou podem contar?",
      },
      {
        id: "45",
        description: "Onde ela estudou no ensino médio?",
      },
      {
        id: "46",
        description:
          "Quem era o melhor amigo dessa pessoa? Eles ainda mantêm contato?",
      },
      {
        id: "47",
        description: "Qual era a matéria favorita dela?",
      },
      {
        id: "48",
        description: "Qual era a matéria que ela menos gostava? Por quê?",
      },
      {
        id: "49",
        description:
          "Ela participou de algum time esportivo? Qual era o esporte favorito?",
      },
      {
        id: "50",
        description:
          "Essa pessoa participava de algum clube especial na escola?",
      },
      {
        id: "51",
        description: "Ela fazia parte do grêmio estudantil?",
      },
      {
        id: "52",
        description:
          "Algum professor foi particularmente influente para ela? Por quê?",
      },
      {
        id: "53",
        description:
          "Como essa pessoa se descreveria como aluna, socialmente e academicamente?",
      },
      {
        id: "54",
        description: "O que ela mais gostava na escola? E o que menos gostava?",
      },
      {
        id: "55",
        description:
          "Em quais atividades extracurriculares essa pessoa participou?",
      },
      {
        id: "56",
        description:
          "Ela ganhou algum prêmio ou foi reconhecida por algum feito?",
      },
      {
        id: "57",
        description:
          "Essa pessoa tinha algum ídolo adolescente? Quem era e por que a inspirava?",
      },
      {
        id: "58",
        description: "Quem foi o primeiro relacionamento dela?",
      },
      {
        id: "59",
        description: "Que tipo de música ela escutava?",
      },
      {
        id: "60",
        description:
          "Qual era a música, banda e dança favorita dela no ensino médio?",
      },
      {
        id: "61",
        description:
          "O que as pessoas de hoje achariam surpreendente sobre ela na adolescência?",
      },
    ],
  },
  {
    id: "4",
    type: "college-and-early-adulthood",
    questions: [
      {
        id: "62",
        description:
          "Essa pessoa frequentou a faculdade? Qual? Se não, por quê?",
      },
      {
        id: "63",
        description: "O que a motivava a frequentar a faculdade?",
      },
      {
        id: "64",
        description: "Quem pagou os estudos dela? Ela mesma ou os pais?",
      },
      {
        id: "65",
        description: "Ela morava em dormitório?",
      },
      {
        id: "66",
        description:
          "Quem foi o colega de quarto mais memorável dela? Ainda há contato?",
      },
      {
        id: "67",
        description: "Por quê essa pessoa decidiu frequentar a faculdade?",
      },
      {
        id: "68",
        description: "Que matérias ela estudou e por quê?",
      },
      {
        id: "69",
        description: "Ela se formou? Em quê?",
      },
      {
        id: "70",
        description:
          "Quantas gerações da família dela receberam diploma antes dela?",
      },
      {
        id: "71",
        description: "A família apoiou a decisão de ir para a faculdade? Como?",
      },
      {
        id: "72",
        description:
          "Como a experiência universitária preparou essa pessoa para o futuro?",
      },
      {
        id: "73",
        description:
          "Se pudesse refazer, ela escolheria um caminho acadêmico diferente?",
      },
      {
        id: "74",
        description: "De que forma a faculdade moldou os interesses dela hoje?",
      },
      {
        id: "75",
        description:
          "Ela sempre sonhou em fazer esse curso ou foi uma decisão amadurecida com o tempo?",
      },
      {
        id: "76",
        description: "Ela fez um diploma avançado? Utilizou-o na carreira?",
      },
      {
        id: "77",
        description: "Em quais atividades no campus ela participou?",
      },
    ],
  },
  {
    id: "5",
    type: "career",
    questions: [
      {
        id: "78",
        description: "Qual foi o primeiro emprego dessa pessoa?",
      },
      {
        id: "79",
        description: "Qual foi o melhor emprego dela?",
      },
      {
        id: "80",
        description: "Qual foi o pior emprego dessa pessoa?",
      },
      {
        id: "81",
        description:
          "Quanto tempo ela permaneceu na mesma empresa? Qual era sua posição?",
      },
      {
        id: "82",
        description:
          "Além de dinheiro, como a carreira dela agregou valor à vida?",
      },
      {
        id: "83",
        description: "Há arrependimentos na trajetória profissional?",
      },
      {
        id: "84",
        description: "Qual é a conquista mais significativa da carreira dela?",
      },
      {
        id: "85",
        description: "Ela já foi demitida por razões injustas?",
      },
      {
        id: "86",
        description: "Qual seria o ambiente de trabalho ideal para ela?",
      },
      {
        id: "87",
        description: "Quem foi a maior influência na carreira dela?",
      },
      {
        id: "88",
        description: "Quem foi o melhor chefe dela? Por que ela o admirava?",
      },
      {
        id: "89",
        description:
          "Essa pessoa já liderou uma equipe? Quais foram os desafios e aprendizados?",
      },
      {
        id: "90",
        description: "Qual seria o trabalho ideal dessa pessoa?",
      },
      {
        id: "91",
        description:
          "Quais desafios profissionais ela enfrentou e como os solucionou?",
      },
    ],
  },
  {
    id: "6",
    type: "love-life",
    questions: [
      {
        id: "92",
        description: "Quem foi o primeiro amor dessa pessoa?",
      },
      {
        id: "93",
        description: "Ela já teve o coração partido?",
      },
      {
        id: "94",
        description: "Ela já partiu o coração de alguém?",
      },
      {
        id: "95",
        description: "Ela já foi casada? Com quem e por quanto tempo?",
      },
      {
        id: "96",
        description: "Como ela se descreveria como namorado(a)?",
      },
      {
        id: "97",
        description: "Como ela e o cônjuge se conheceram?",
      },
      {
        id: "98",
        description: "Como aconteceu o pedido de união?",
      },
      {
        id: "99",
        description: "Como foi o casamento e a lua de mel dessa pessoa?",
      },
      {
        id: "100",
        description: "Ela acredita em amor à primeira vista?",
      },
      {
        id: "101",
        description: "Ela já se divorciou?",
      },
      {
        id: "102",
        description: "Qual foi o pior término de relacionamento dessa pessoa?",
      },
      {
        id: "103",
        description: "Quem foi a maior paixão dela?",
      },
      {
        id: "104",
        description: "Qual é o papel dela em casamentos ou relacionamentos?",
      },
      {
        id: "105",
        description: "Ela tem algum arrependimento em relação à vida amorosa?",
      },
      {
        id: "106",
        description:
          "Qual foi a maneira mais especial que ela demonstrou amor a alguém?",
      },
      {
        id: "107",
        description:
          "Qual foi a maneira mais especial que alguém demonstrou amor a ela?",
      },
    ],
  },
  {
    id: "7",
    type: "political-and-social-causes",
    questions: [
      {
        id: "108",
        description:
          "Qual é o evento político mais significativo que ocorreu durante a vida dessa pessoa?",
      },
      {
        id: "109",
        description:
          "Qual é o evento político mais significativo da história da humanidade, segundo ela?",
      },
      {
        id: "110",
        description: "Qual figura política essa pessoa mais admira? Por quê?",
      },
      {
        id: "111",
        description: "Qual figura política ela menos admira? Por quê?",
      },
      {
        id: "112",
        description:
          "Além do presente, em qual era histórica ela gostaria de ter vivido?",
      },
      {
        id: "113",
        description:
          "Qual é a guerra mais importante que ocorreu durante a vida dela? E na história da humanidade?",
      },
      {
        id: "114",
        description: "Qual guerra ela considera menos importante?",
      },
      {
        id: "115",
        description: "Essa pessoa já lutou por alguma causa política?",
      },
      {
        id: "116",
        description:
          "Quais movimentos sociais afetaram a consciência social dela e como?",
      },
      {
        id: "117",
        description:
          "O que ela gostaria de mudar no atual cenário político ou social?",
      },
      {
        id: "118",
        description: "Ela é politicamente ativa?",
      },
      {
        id: "119",
        description:
          "Se tivesse que escolher, por qual causa política ou social essa pessoa daria sua vida?",
      },
      {
        id: "120",
        description:
          "Se pudesse conhecer qualquer ícone histórico, quem seria e por quê?",
      },
    ],
  },
  {
    id: "8",
    type: "general-topics",
    questions: [
      {
        id: "121",
        description: "Qual é a definição de 'felicidade' para essa pessoa?",
      },
      {
        id: "122",
        description: "Qual foi o pior desapontamento que ela já teve?",
      },
      {
        id: "123",
        description: "Qual celebridade ela mais gostaria de conhecer?",
      },
      {
        id: "124",
        description:
          "Qual foi a experiência de viagem mais memorável dessa pessoa?",
      },
      {
        id: "125",
        description: "Qual é o lugar mais distante de casa que ela já esteve?",
      },
      {
        id: "126",
        description: "Qual foi a coisa mais engraçada que aconteceu com ela?",
      },
      {
        id: "127",
        description: "Qual foi o momento mais rebelde da vida dela?",
      },
      {
        id: "128",
        description:
          "Se tivesse que escolher, qual é a única coisa de que ela se arrepende na maneira como viveu a vida?",
      },
      {
        id: "129",
        description: "Qual é a memória mais feliz dessa pessoa?",
      },
      {
        id: "130",
        description: "E a mais triste?",
      },
      {
        id: "131",
        description: "De qual conquista ela mais se orgulha?",
      },
      {
        id: "132",
        description: "Se pudesse refazer tudo, o que ela faria de diferente?",
      },
      {
        id: "133",
        description: "Como ela espera ser lembrada?",
      },
      {
        id: "134",
        description: "Como ela acha que as pessoas vão se lembrar dela?",
      },
      {
        id: "135",
        description: "Quem é o maior fã dela?",
      },
      {
        id: "136",
        description: "De quem ela é o maior fã?",
      },
      {
        id: "137",
        description: "O que ela gosta de fazer no tempo livre?",
      },
      {
        id: "138",
        description:
          "Qual é a única pergunta à qual essa pessoa sempre responde com uma mentira?",
      },
    ],
  },
];
