import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import { ModalPageLoadingAiNextProps } from "./ModalPageLoadingAiNext.types";
import { AnimatePresence, motion } from "framer-motion";
import { Bot } from "lucide-react";
import { ContentAudio } from "../contentAudio";

export function ModalPageLoadingAiNext({
  is_open,
  title,
  description = "",
  titleBtn,
  onUpdate,
  statusModal,
  onRefresh,
  onClonse,
  children,
  id,
  onSendText,
  ...rest
}: ModalPageLoadingAiNextProps) {
  const renderContent = () => {
    switch (statusModal) {
      case "processing": {
        return (
          <AnimatePresence>
            <Box
              style={{ textAlign: "center" }}
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="100%"
              py="8"
            >
              <motion.div
                animate={{
                  scale: [1, 1.2, 1],
                  rotate: [0, 360],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              >
                <Bot className="size-20" style={{ color: "#36A9E1" }} />
              </motion.div>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="mt-6 text-lg font-semibold"
                style={{ color: "#36A9E1" }}
              >
                {title}
              </motion.p>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="mt-2 text-sm"
                style={{ color: "#36A9E1" }}
              >
                {description}
              </motion.p>
              <motion.div
                className="w-64 h-2 bg-gray-200 rounded-full mt-6 overflow-hidden"
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 65, ease: "linear" }}
              >
                <motion.div
                  className="h-full"
                  style={{ backgroundColor: "#36A9E1" }}
                  initial={{ width: "0%" }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 60, ease: "linear" }}
                />
              </motion.div>
            </Box>
          </AnimatePresence>
        );
      }
      case "error":
      case "alert": {
        return (
          <AnimatePresence>
            <motion.div>
              <div
                style={{ textAlign: "center" }}
                className="flex flex-col space-y-4 mb-4 bg-red"
              >
                <p>
                  <span className="font-semibold">{title}</span>
                </p>
                <p>{description}</p>
              </div>
              {children && children}
              <div className="flex space-x-4">
                <Button
                  width="100%"
                  bg="blue.400"
                  _hover={{
                    bg: "blue.400",
                  }}
                  onClick={() => {
                    if (onRefresh) {
                      onRefresh();
                    }
                  }}
                >
                  {titleBtn}
                </Button>
                {onClonse && (
                  <Button
                    variant="outline"
                    width="60%"
                    _hover={{
                      bg: "blue.400",
                    }}
                    onClick={() => {
                      if (onClonse) {
                        onClonse();
                      }
                    }}
                  >
                    Fechar
                  </Button>
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        );
      }
      case "audio": {
        return (
          <ContentAudio
            id={id ? id : "empty"}
            onClose={() => {
              if (onClonse) {
                onClonse();
              }
            }}
            onSendText={(txt) => {
              if (onSendText) {
                onSendText(txt);
              }
            }}
          />
        );
      }
      case "children":
        return <>{children}</>;
      default:
        return <Flex />;
    }
  };
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          if (onUpdate) {
            onUpdate("processing");
          }
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="md"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalBody>
            <Flex
              padding="30px"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              {renderContent()}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
