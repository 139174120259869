import { useState } from "react";
import { api } from "../../../../service/api";

export function useTypeQuestionShow() {
  const [loadingMe, setLoadingMe] = useState(false);
  const [errorMe, setErrorMe] = useState<boolean | null>(null);
  const [isQuestionMe, setIsQuestionMe] = useState<boolean | null>(null);

  const fetchData = async (id: string) => {
    try {
      setLoadingMe(true);
      const response = await api.get(`baseinformation/typebiografia/${id}`);
      setIsQuestionMe(response.data.is_me);
    } catch (error) {
      setErrorMe(true);
    } finally {
      setLoadingMe(false);
    }
  };

  return {
    isQuestionMe,
    loadingMe,
    errorMe,
    refetchMe: fetchData,
  };
}
