import { Box, Flex, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../../../utils/utils";
import { OrderBook } from "../../../../dtos/UsersDTO";

type Props = {
  orderBook: OrderBook;
};

export function CardSubscription({ orderBook }: Props) {
  return (
    <Flex
      width={["100%", "100%", "248px"]}
      minHeight="301px"
      borderRadius="15px"
      borderWidth="1px"
      borderColor="#36A9E1"
      px="25px"
      py="22px"
      flexDirection="column"
    >
      <Flex flexDirection="column" flex={1}>
        <Text color="#A1A1A1" fontSize="12px" fontWeight={600}>
          {orderBook.order.plan.name}
        </Text>
        <Text color="#383838" fontSize="18px" fontWeight={700}>
          R$ {formatCurrency(orderBook.order.amount_total / 100)}
        </Text>
        <Box
          width="100%"
          height="1.5px"
          mt="5px"
          mb="7px"
          backgroundColor="#D8D8D8"
        />

        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {orderBook.order.plan.quantity_pages} páginas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {orderBook.order.plan.quantity_copies_books} Cópias Impressas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          Formato de Ebook (Ilimitado)
        </Text>
      </Flex>

      <Flex
        bg="blue.400"
        color="white"
        style={{
          borderRadius: 50,
          height: 32,
          fontSize: 15,
          fontWeight: 600,
        }}
        _hover={{
          bg: "blue.400",
        }}
        mt="6"
        alignItems="center"
        justifyContent="center"
      >
        {orderBook.status === "created" ? "Disponível para uso" : "Em uso"}
      </Flex>
    </Flex>
  );
}
