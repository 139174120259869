import { Flex } from "@chakra-ui/react";
import { Title } from "../../components/Title";
import { useAuth } from "../../hooks/useAuth";
import { ContainerSmall } from "../../components/ContainerSmall";
import { CardSubscription } from "./components/cardSubscription";

export function Subscription() {
  const { user } = useAuth();

  return (
    <ContainerSmall>
      <Flex
        width={["auto", "auto", "100%"]}
        marginTop="41px"
        marginBottom="50px"
        flexDirection="column"
      >
        <Title title="Planos" isDark={false} isIcon={false} />

        <Flex mt="20px" gap="20px">
          {user.subscription?.order_book.map((item, _) => (
            <CardSubscription key={item.id} orderBook={item} />
          ))}
        </Flex>
      </Flex>
    </ContainerSmall>
  );
}
