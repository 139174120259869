import { Box, Spinner } from "@chakra-ui/react";

export function AllLoading() {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top={0}
      left={0}
      zIndex={999}
      bg="white"
      opacity={0.5}
    >
      <Spinner
        color="blue.400"
        emptyColor="white"
        thickness="4px"
        size="xl"
        h={100}
        w={100}
      />
    </Box>
  );
}
