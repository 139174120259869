import { useState } from "react";
import { PlanDTO } from "../../../../dtos/PlanDTO";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { api } from "../../../../service/api";
import { formatCurrency } from "../../../../utils/utils";

type Props = {
  plans: PlanDTO;
};

export function CardPlan2({ plans }: Props) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function createSubscription(plan_id: string) {
    try {
      const data = {
        plan_id,
      };
      setLoading(true);
      const response = await api.post("/payments/create/subscription", data);
      toast({
        position: "top-right",
        title: "Assinatura",
        description:
          "Sua assinatura foi iniciada. Você será direcionado para pagamento",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      window.location.replace(response.data.link_pay);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Assinatura",
        description: "Não foi possivel criar assinatura.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      width={["100%", "100%", "248px"]}
      minHeight="301px"
      borderRadius="15px"
      borderWidth="1px"
      borderColor="#36A9E1"
      px="25px"
      py="22px"
      flexDirection="column"
    >
      <Flex flexDirection="column" flex={1}>
        <Text color="#A1A1A1" fontSize="12px" fontWeight={600}>
          {plans.name}
        </Text>
        <Text color="#383838" fontSize="18px" fontWeight={700}>
          R$ {formatCurrency(plans.unit_amount / 100)}
        </Text>
        <Box
          width="100%"
          height="1.5px"
          mt="5px"
          mb="7px"
          backgroundColor="#D8D8D8"
        />

        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {plans.quantity_pages} páginas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {plans.quantity_copies_books} Cópias Impressas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          Formato de Ebook (Ilimitado)
        </Text>
      </Flex>

      <Button
        isLoading={loading}
        type="button"
        bg="blue.400"
        color="white"
        style={{
          borderRadius: 50,
          height: 32,
          fontSize: 15,
          fontWeight: 600,
        }}
        _hover={{
          bg: "blue.400",
        }}
        mt="6"
        size="lg"
        onClick={() => {
          createSubscription(plans.id);
        }}
      >
        Contratar
      </Button>
    </Flex>
  );
}
