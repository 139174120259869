import { Flex, IconButton } from "@chakra-ui/react";
import { RiCheckFill } from "react-icons/ri";
import { Input } from "../../../../../components/form/input";
import { useEffect, useState } from "react";
import { api } from "../../../../../service/api";
import { useShowBookData } from "../../hooks/useShowBookData";

type Props = {
  id: string;
  pageType: string;
};
export function TitleForm({ id, pageType }: Props) {
  const { dataList, loading, refetch } = useShowBookData();

  const [title, setTitle] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);

  async function sendData() {
    try {
      setLoadingSend(true);
      await api.post("book/chapter/create", {
        biography_id: id,
        category_name: pageType,
        title,
      });

      refetch({ id, categoryName: pageType });
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  async function updateData() {
    try {
      setLoadingSend(true);
      await api.put(`book/chapter/update/${dataList.id}`, {
        biography_id: id,
        category_name: pageType,
        title: title,
        content: title,
      });
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  useEffect(() => {
    refetch({ id, categoryName: pageType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageType]);

  useEffect(() => {
    if (dataList.id) {
      setTitle(dataList.title);
    }
  }, [dataList]);

  return (
    <Flex mt="30px" width={["100%", "100%", "auto"]}>
      <Flex
        gap="10px"
        justifyContent="center"
        alignItems="center"
        flexDirection={["column", "column", "row"]}
        height="50px"
        width={["100%", "100%", "auto"]}
      >
        <Input
          name="title"
          width={["100%", "100%", "400px"]}
          placeholder="Informe o título do livro"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          isDisabled={loading || loadingSend}
        />
        <IconButton
          width={["100%", "100%", "auto"]}
          padding={["25px", "30px", "auto"]}
          aria-label="Salvar"
          colorScheme="blue"
          isDisabled={title ? false : true}
          isLoading={loading || loadingSend}
          icon={<RiCheckFill />}
          onClick={() => {
            if (dataList.id) {
              updateData();
            } else {
              sendData();
            }
          }}
        />
      </Flex>
    </Flex>
  );
}
