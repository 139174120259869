import {
  Flex,
  Icon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { IntroductionText } from "./components/introductionText";
import { TitleTabBaseInformation } from "../components/titleTabBaseInformation";
import { IconTextBaseInformation } from "../components/icons/iconTextBaseInformation";
import { IconDocumentsBaseInformation } from "../components/icons/iconDocumentsBaseInformation";
// import { IconImageBaseInformation } from "../components/icons/iconImageBaseInformation";
import { IconAudioBaseInformation } from "../components/icons/iconAudioBaseInformation";
import { IntroductionDocuments } from "./components/introductionDocuments";
import { IntroductionAudio } from "./components/introductionAudio";

type Props = {
  id: string;
  type: string;
  title: string;
  locality: string;
  description: string;
};
export function IntroductionBaseInformation({
  id,
  title,
  type,
  locality,
  description,
}: Props) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Text
        color="#1E1E1E"
        display={["none", "none", "flex"]}
        fontSize="16px"
        fontWeight="bold"
      >
        {title}
      </Text>

      <Flex mt={["0px", "0px", "20px"]} width="100%">
        <Tabs
          colorScheme="blue"
          width="100%"
          index={tabIndex}
          onChange={handleTabsChange}
          isLazy
          borderBottomColor="#DDDDDD"
        >
          <TabList>
            {type !== "others-topics" && (
              <Tab _selected={{ color: "blue.400", bg: "transparent" }}>
                <TitleTabBaseInformation
                  title="Textos"
                  icon={
                    <Icon
                      as={IconTextBaseInformation}
                      fontSize="18px"
                      color="blue.400"
                    />
                  }
                />
              </Tab>
            )}

            <Tab _selected={{ color: "blue.400", bg: "transparent" }}>
              <TitleTabBaseInformation
                title="Documentos"
                icon={
                  <Icon
                    as={IconDocumentsBaseInformation}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
              />
            </Tab>
            {/* <Tab _selected={{ color: "blue.400", bg: "transparent" }}>
              <TitleTabBaseInformation
                title="Imagens"
                icon={
                  <Icon
                    as={IconImageBaseInformation}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
              />
            </Tab> */}
            {/* <Tab _selected={{ color: "blue.400", bg: "transparent" }}>
              <TitleTabBaseInformation
                title="Vídeos"
                icon={
                  <Icon
                    as={IconVideoBaseInformation}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
              />
            </Tab> */}
            <Tab _selected={{ color: "blue.400", bg: "transparent" }}>
              <TitleTabBaseInformation
                title="Áudios"
                icon={
                  <Icon
                    as={IconAudioBaseInformation}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
              />
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.400"
            borderRadius="1px"
          />
          <TabPanels>
            {type !== "others-topics" && (
              <TabPanel>
                <IntroductionText id={id} type={type} />
              </TabPanel>
            )}

            <TabPanel>
              <IntroductionDocuments id={id} type={type} />
            </TabPanel>
            {/*<TabPanel>
              <IntroductionImage id={id} type={type} />
             </TabPanel>
            <TabPanel>
              <IntroductionVideo id={id} type={type} />
            </TabPanel> */}
            <TabPanel>
              <IntroductionAudio id={id} type={type} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  );
}
