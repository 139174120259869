import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import { useState } from "react";

interface IUserInfoGoogle {
  id: string;
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  picture: string;
  verified_email: boolean;
}

export function useGetUserInfoGoogle() {
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [dataUserGoogle, setDataUserGoogle] = useState<IUserInfoGoogle>(
    {} as IUserInfoGoogle
  );

  const getUserInfoGoogle = async (
    accessToken: string
  ): Promise<IUserInfoGoogle> => {
    try {
      setIsLoadingUser(true);
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      setDataUserGoogle(response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingUser(false);
      googleLogout();
    }
  };

  return { isLoadingUser, getUserInfoGoogle, dataUserGoogle };
}
