import { Flex, Image } from "@chakra-ui/react";

type Props = {
  bookImageIreview: string;
  isSelected: boolean;
  onPress: () => void;
};

export function CoverItem({ bookImageIreview, isSelected, onPress }: Props) {
  return (
    <Flex
      borderWidth="8px"
      borderColor={isSelected ? "green.500" : "gray.100"}
      borderRadius="18px"
      cursor="pointer"
      _hover={{
        borderColor: "green.500",
      }}
      onClick={() => onPress()}
    >
      <Image
        src={"https://cdn.cursodeinglesparabrasil.com.br/" + bookImageIreview}
        alt={"book"}
        width="200px"
        height="300px"
        borderRadius="10px"
        objectFit="cover"
      />
    </Flex>
  );
}
