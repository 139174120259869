import { Flex, Icon, Text } from "@chakra-ui/react";
import { SelectOptionMenuProps } from "./SelectOptionMenu.types";
import { RiExpandUpDownLine } from "react-icons/ri";

export function BiographySelectOptionMenu({
  onPress,
  title,
}: SelectOptionMenuProps) {
  return (
    <Flex flexDirection="column" minW="100%" width="100%">
      <Flex
        mt="8px"
        borderRadius="10px"
        px="20px"
        py="15px"
        backgroundColor="#F8FAF8"
        alignItems="center"
        cursor="pointer"
        onClick={onPress}
        gap="10px"
      >
        <Icon fontSize="18px" as={RiExpandUpDownLine} color="#36A9E1" />

        <Text fontWeight={700} fontSize="16px" color="#6A6A6A">
          {title}
        </Text>
      </Flex>
    </Flex>
  );
}
