import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { TermsOfSse } from "../../../../components/termsOfSse";
import { PrivacyPolicy } from "../../../../components/privacyPolicy";

export type typeContractValue = "term" | "policy" | "empty";

type Props = {
  is_open: boolean;
  onClose: () => void;
  typeContract: typeContractValue;
};
export function ModalContract({
  is_open,
  onClose,
  typeContract,
  ...rest
}: Props) {
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={onClose}
        isCentered
        {...rest}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader textAlign="center">
            {typeContract === "term"
              ? "Termos de Uso"
              : "Política de Privacidade"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={10} pb={10}>
            {typeContract === "term" ? <TermsOfSse /> : <PrivacyPolicy />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
