import { Box, Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { InputPin } from "../../../components/form/inputPin";
import { InputPassword } from "../../../components/form/inputPassword";
import { api } from "../../../service/api";
import { IMAGE_LOGIN, LOGO, LOGO_BRANCA } from "../../../assets";

type authFormData = {
  email: string;
  recover_token: string;
  password: string;
  re_password: string;
};

const authFormDataSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  recover_token: yup
    .string()
    .required("Campo obrigatório.")
    .min(6, "Campo obrigatório.")
    .max(6, "Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
  re_password: yup
    .string()
    .required("Confirme a senha")
    .oneOf([yup.ref("password"), ""], "Confirme a senha"),
});

export function UpdatePassword() {
  const navigate = useNavigate();
  let { email } = useParams();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authFormDataSchema) });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<authFormData> = async (value) => {
    try {
      await api.post("updatepassword", {
        email: value.email,
        recover_token: value.recover_token,
        password: value.password,
        confirm_password: value.re_password,
      });
      navigate("/login");
    } catch (error) {
      navigate("/login");
    }
  };

  return (
    <Flex
      w="100%"
      h="100vh"
      minHeight="100%"
      alignItems="center"
      justifyContent="center"
      bg="white"
    >
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="white"
        w={["100%", "100%", "55%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image src={LOGO} style={{ width: "226px" }} alt="logo" />
        </Flex>

        <Flex
          style={{ marginTop: 80 }}
          flex={1}
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex
            flexDirection="column"
            style={{ width: "380px", height: "328px" }}
          >
            <Text style={{ fontWeight: 600, fontSize: 24 }} color="gray.200">
              Atualização da senha
            </Text>

            <Text color="gray.50">
              Entre com o código de recuperação e a nova senha
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Stack mt={5} spacing={4}>
                <Input
                  label="Endereço de email"
                  type="email"
                  isDisabled
                  {...register("email", { value: email })}
                  error={errors.email}
                  placeholder="Informe o email"
                />

                <Controller
                  control={control}
                  name="recover_token"
                  render={({ field: { onChange, value } }) => (
                    <InputPin
                      label="Código de segurança"
                      name="recover_token"
                      onChange={onChange}
                      error={errors.recover_token}
                    />
                  )}
                />

                <InputPassword
                  label="Senha"
                  type="password"
                  style={{ marginTop: 10, fontSize: 14 }}
                  {...register("password")}
                  error={errors.password}
                  placeholder="Informe a nova senha"
                />

                <InputPassword
                  label="Confirmar senha"
                  type="password"
                  style={{ marginTop: 10, fontSize: 14 }}
                  {...register("re_password")}
                  error={errors.re_password}
                  placeholder="Confirme a nova senha"
                />
              </Stack>

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                mt="6"
                size="lg"
              >
                Atualizar senha
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Link
                  href="/Login"
                  color="gray.200"
                  style={{ fontWeight: 600, fontSize: 16 }}
                >
                  Voltar
                </Link>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "45%" }}
        display={["none", "none", "flex"]}
        bg="white"
        position="relative"
      >
        <Image
          src={IMAGE_LOGIN}
          style={{ width: "100%", objectFit: "cover" }}
          alt="imagem robo"
        />
        <Flex
          position="absolute"
          top="38%"
          left="30%"
          width="396px"
          height="263px"
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "396px", height: "263px" }}
            alt="logo"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
