import { Navigate, Route, Routes } from "react-router-dom";
import { RecoverPassword } from "../pages/auth/recoverPassword";
import { Login } from "../pages/auth/login";

import { AuthRoute } from "./auth-route";

import { UpdatePassword } from "../pages/auth/updatePassword";
import { RegisterAccount } from "../pages/auth/registerAccount";
import { RegisterAccountSuccess } from "../pages/auth/registerAccount/success";

import { NotFound } from "../pages/extra/notFound";
import { Home } from "../pages/home";
import { CreateCategories } from "../pages/extra/categories/create";
import { ListRanking } from "../pages/extra/ranking";
import { PaymentCallback } from "../pages/extra/payment/callback";
import { Plan } from "../pages/plan";
import { Subscription } from "../pages/subscription";
import { BiographyChatbot } from "../pages/biography/chatbot";
import { BaseInformation } from "../pages/biography/baseInformation";
import { BookData } from "../pages/biography/bookData";
import { AccountMe } from "../pages/account/me";
import { BiographyShow } from "../pages/biographyShow";
import { PhysicalBook } from "../pages/physicalBook";
import { AuthSoccial } from "./auth-soccial";
import { PrivacyPolicyPage } from "../pages/extra/privacyPolicy";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />

      <Route
        path="/login"
        element={
          <AuthSoccial>
            <Login />
          </AuthSoccial>
        }
      />
      <Route path="/recuperacao-senha" element={<RecoverPassword />} />

      <Route path="/atualizar-senha/:email" element={<UpdatePassword />} />
      <Route
        path="/cadastro"
        element={
          <AuthSoccial>
            <RegisterAccount />
          </AuthSoccial>
        }
      />
      <Route path="/cadastro/sucesso" element={<RegisterAccountSuccess />} />
      <Route path="/pagamento" element={<PaymentCallback />} />

      <Route
        path="/politica-de-privacidade"
        element={<PrivacyPolicyPage typeContract="policy" />}
      />
      <Route
        path="/termos-de-uso"
        element={<PrivacyPolicyPage typeContract="term" />}
      />

      <Route
        path="/biografia/chatboot/:id"
        element={
          <AuthRoute path="biografia">
            <BiographyChatbot />
          </AuthRoute>
        }
      />

      <Route
        path="/biografia/base-conhecimento/:id"
        element={
          <AuthRoute path="biografia">
            <BaseInformation />
          </AuthRoute>
        }
      />

      <Route
        path="/biografia/meu-livro/:id"
        element={
          <AuthRoute path="biografia">
            <BookData />
          </AuthRoute>
        }
      />

      <Route
        path="/home"
        element={
          <AuthRoute path="home">
            <Home />
          </AuthRoute>
        }
      />

      <Route
        path="/livro/meu-livro/:id"
        element={
          <AuthRoute path="livro">
            <BiographyShow />
          </AuthRoute>
        }
      />

      <Route
        path="/livro/solicitar-livro-fisico/:id"
        element={
          <AuthRoute path="livro">
            <PhysicalBook />
          </AuthRoute>
        }
      />

      <Route
        path="/planos"
        element={
          <AuthRoute path="/planos">
            <Plan />
          </AuthRoute>
        }
      />

      <Route
        path="/assinatura"
        element={
          <AuthRoute path="/assinatura">
            <Subscription />
          </AuthRoute>
        }
      />

      <Route
        path="/minha-conta"
        element={
          <AuthRoute path="accountme">
            <AccountMe />
          </AuthRoute>
        }
      />

      <Route
        path="/configuracoes"
        element={
          <AuthRoute path="configuracoes">
            <Subscription />
          </AuthRoute>
        }
      />

      <Route
        path="/nova/configuracoes"
        element={
          <AuthRoute
            path="/configuracoes"
            subPath="configuracoes"
            subTitle="nova configuração"
            title="Início"
          >
            <CreateCategories />
          </AuthRoute>
        }
      />

      <Route
        path="/ranking"
        element={
          <AuthRoute path="/ranking" subPath="Ranking" title="Início">
            <ListRanking />
          </AuthRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
