import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { api } from "../../../../../service/api";
import { RiArrowGoBackFill, RiCheckFill, RiEdit2Fill } from "react-icons/ri";
import { BoxLoading } from "../../../../../components/boxLoading";

type Props = {
  message: string;
  id: string;
};
export function BoxMessageUser({ message, id }: Props) {
  const [selectedItem, setSelectedItem] = useState("");
  const [textValue, setTextValue] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Salvar"
          colorScheme="blue"
          icon={<RiCheckFill />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancelar"
          colorScheme="blue"
          icon={<RiArrowGoBackFill />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <ButtonGroup
        justifyContent="center"
        size="sm"
        display={selectedItem === id ? "flex" : "none"}
      >
        <IconButton
          size="sm"
          aria-label="Editar"
          colorScheme="blue"
          icon={<RiEdit2Fill />}
          {...getEditButtonProps()}
        />
      </ButtonGroup>
    );
  }

  async function sendMessage() {
    try {
      if (textValue === "") return;

      setLoading(true);
      await api.put(`chatbot/message/update/${id}`, {
        message: textValue,
      });
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Messagem atualizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Não foi possível finalizar atualização.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      alignSelf="flex-end"
      background="blue.100"
      borderRadius="20px"
      p="20px"
    >
      {loading && <BoxLoading />}
      <Editable
        textAlign="center"
        defaultValue={message}
        fontSize={["14px", "14px", "16px"]}
        fontWeight={400}
        color="#1E1E1E"
        isPreviewFocusable={false}
        onChange={(newValue) => {
          setTextValue(String(newValue));
        }}
        onSubmit={() => {
          sendMessage();
        }}
      >
        <Flex
          gap="20px"
          onMouseOver={() => {
            setSelectedItem(id);
          }}
          onMouseOut={() => {
            setSelectedItem("");
          }}
        >
          <Flex flex={1}>
            <EditablePreview />
            <Input as={EditableInput} />
          </Flex>
          <EditableControls />
        </Flex>
      </Editable>
    </Flex>
  );
}
