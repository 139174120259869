import { Box, Flex, Text } from "@chakra-ui/react";
import { typeContractValue } from "../../auth/registerAccount/modal/modal-contract";
import { TermsOfSse } from "../../../components/termsOfSse";
import { PrivacyPolicy } from "../../../components/privacyPolicy";

type Props = {
  typeContract: typeContractValue;
};

export function PrivacyPolicyPage({ typeContract }: Props) {
  return (
    <Box px={10} pb={10}>
      <Box margin="auto" mt="20px" maxWidth="900px">
        <Text
          textAlign="center"
          style={{
            color: "#1E1E1E",
            fontWeight: 600,
          }}
          fontSize={["18px", "18px", "20px"]}
          mb={["10px", "10px", "15px"]}
        >
          {typeContract === "term"
            ? "Termos de Uso"
            : "Política de Privacidade"}
        </Text>
        <Flex>
          {typeContract === "term" ? <TermsOfSse /> : <PrivacyPolicy />}
        </Flex>
      </Box>
    </Box>
  );
}
