import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../../../../service/api";
import { ModalNewIntroductionTextProps } from "./ModalNewIntroductionText.types";
import { InputTextArea } from "../../../../../../../components/form/textarea";
import { typesBaseInformation } from "../../../../../../../data/data";
import { useEffect, useState } from "react";
import { AllLoading } from "../../../../../../../components/allLoading";

type ModalFormData = {
  description: string;
};

const modalFormSchema = yup.object().shape({
  description: yup.string().required("Informe o campo obrigatório."),
});

export function ModalNewIntroductionText({
  id,
  title,
  biography_id,
  type,
  is_open,
  onClose,
  onCloseSend,
  type_modal,
  text_input,
  id_form,
  ...rest
}: ModalNewIntroductionTextProps) {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [valueBlob, setValueBlob] = useState(null);

  const recorderControls = useAudioRecorder();
  const addAudioElement = (blob: any) => {
    const myFile = new File([blob], "audio.mp3", {
      type: blob.type,
    });

    if (blob === valueBlob) {
      return;
    }

    setValueBlob(blob);

    console.log(myFile.size);
    console.log(blob);

    sendAudio(myFile);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(modalFormSchema),
  });

  async function sendAudio(file: File) {
    try {
      setLoading(true);
      const audioFile = new FormData();
      audioFile.append("biography_id", id);
      audioFile.append("path", file);
      audioFile.append("title", file.name);
      audioFile.append("description", file.name);
      const response = await api.post(
        "chatbot/audio/message/create",
        audioFile
      );

      setValue("description", response.data.text);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (text_input) {
      setValue("description", text_input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text_input]);

  const handleSend: SubmitHandler<ModalFormData> = async (value) => {
    try {
      const titleValue = typesBaseInformation.find(
        (item) => item.type === type
      )?.title;

      if (type_modal === "create") {
        await api.post("baseinformation/create", {
          type,
          locality: "text",
          biography_id: biography_id,
          title: title ?? titleValue,
          description: value.description,
          id_ref: id,
        });
      } else if (type_modal === "update") {
        await api.put(`baseinformation/update/text/${id_form}`, {
          type,
          locality: "text",
          biography_id: biography_id,
          title: title ?? titleValue,
          description: value.description,
          id_ref: id,
        });
      }
      reset();
      onCloseSend();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar novo livro",
        description: "Não foi possível criar novo livro.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          reset();
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#181818",
              fontSize: 16,
              fontWeight: 600,
            }}
          ></ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={5}>
            <Flex
              flexDirection="column"
              as="form"
              onSubmit={handleSubmit(handleSend)}
              gap="15px"
            >
              {id && is_open && (
                <Flex justifyContent="center" alignItems="center">
                  {loading && <AllLoading />}
                  <AudioRecorder
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    showVisualizer
                  />
                </Flex>
              )}

              <InputTextArea
                label={title}
                placeholder="Responda aqui:"
                size="xl"
                error={errors.description}
                {...register("description")}
              />
              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                size="lg"
              >
                Enviar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
