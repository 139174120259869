import { Button, Flex, IconButton, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { api } from "../../service/api";
import { InputTextArea } from "../form/textarea";
import { FullLoading } from "../fullLoading";
import { RiCloseCircleLine } from "react-icons/ri";

type Props = {
  id: string;
  onSendText: (text: string) => void;
  onClose: () => void;
};

export function ContentAudio({ id, onClose, onSendText }: Props) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const recorderControls = useAudioRecorder();
  const addAudioElement = (blob: any) => {
    // const url = URL.createObjectURL(blob);
    // const audio = document.createElement("audio");
    // audio.src = url;
    // audio.controls = true;
    // document.body.appendChild(audio);

    const myFile = new File([blob], "audio.mp3", {
      type: blob.type,
    });

    sendAudio(myFile);
  };

  async function sendAudio(file: File) {
    try {
      setLoading(true);
      const audioFile = new FormData();
      audioFile.append("biography_id", id);
      audioFile.append("path", file);
      audioFile.append("title", file.name);
      audioFile.append("description", file.name);
      const response = await api.post(
        "chatbot/audio/message/create",
        audioFile
      );

      setText(response.data.text);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <FullLoading />}
      <Flex flexDirection="column" alignItems="center" width="100%">
        <IconButton
          isRound={true}
          variant="outline"
          borderColor="blue.400"
          _hover={{
            backgroundColor: "white",
          }}
          aria-label="audio"
          fontSize="20px"
          icon={
            <Icon as={RiCloseCircleLine} fontSize="18px" color="blue.400" />
          }
          isDisabled={loading}
          onClick={() => {
            onClose();
            setText("");
          }}
          top="10px"
          right="10px"
          alignSelf="flex-end"
          position="absolute"
        />
        <AudioRecorder
          onRecordingComplete={(blob) => addAudioElement(blob)}
          recorderControls={recorderControls}
          showVisualizer
        />
        {text && (
          <Flex mt="20px" flexDirection="column" width="100%">
            <InputTextArea
              name="audioText"
              width="100%"
              label="Texto do áudio"
              placeholder="Descrição:"
              size="lg"
              value={text}
              onChange={(e) => {
                let newText = e.target.value;
                setText(newText);
              }}
            />
            <Button
              type="button"
              bg="blue.400"
              color="white"
              style={{
                borderRadius: 10,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
              }}
              _hover={{
                bg: "blue.400",
              }}
              mt="6"
              size="lg"
              onClick={() => {
                onSendText(text);
                setText("");
              }}
            >
              Enviar
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
}
