import "./styles.scss";
import { Flex } from "@chakra-ui/react";

export function PrivacyPolicy() {
  return (
    <Flex className="privacyPolicy" style={{ flexDirection: "column" }}>
      <h1>Introdução e Objetivo</h1>
      <p>
        Bem-vindo à OCA BOOKS! Estamos comprometidos em proteger sua privacidade
        e a segurança das informações pessoais que você nos confia.
      </p>
      <p>
        This Policy of Privacy describes how we collect, use, store and protect
        your data when you access and use our services. By using our platform,
        you agree to the practices described in this policy, which was developed
        to ensure transparency and confidence in our relationship, committing to
        the protection of personal data as applicable laws, including LGPD and
        GDPR.
      </p>
      <p>
        We recommend that you read carefully the following details, as they
        contain important information about your rights and our approach to your
        privacy.
      </p>

      <h2>1. Glossário de Termos de Privacidade</h2>
      <p>Os termos abaixo são utilizados nesta política:</p>
      <ul>
        <li>
          <strong>Dados Pessoais: </strong> Qualquer informação que possa
          identificar uma pessoa física, como nome, e-mail, telefone, endereço,
          informações de pagamento, entre otros.
        </li>
        <li>
          <strong>Tratamento de Dados Pessoais:</strong> Operações realizadas
          com dados pessoais, como coleta, armazenamento, consulta,
          compartilhamento e exclusão.
        </li>
        <li>
          <strong>Titular de Dados:</strong> A pessoa física a quem os dados
          pessoais se referem.
        </li>
        <li>
          <strong>Controlador de Dados:</strong> Entidade que define como e por
          que os dados pessoais serão tratados. Na OCA BOOKS, somos os
          Controladores.
        </li>
        <li>
          <strong>Processador de Dados:</strong> Entidade que realiza o
          tratamento de dados em nome do Controlador.
        </li>
        <li>
          <strong>Encarregado de Proteção de Dados (DPO):</strong> Responsável
          por supervisionar a conformidade com as leis de proteção de dados.
        </li>
        <li>
          <strong>Usuários:</strong> Indivíduos que utilizam a plataforma OCA
          BOOKS para criar livros e projetos editoriais.
        </li>
        <li>
          <strong>Consentimento:</strong> Permissão explícita para o tratamento
          de dados, conforme exigido pela LGPD.
        </li>
        <li>
          <strong>Política de Retenção de Dados:</strong> Diretrizes que
          determinam por quanto tempo os dados serão armazenados.
        </li>
        <li>
          <strong>Direitos dos Titulares:</strong> Conjunto de direitos
          garantidos pela LGPD e GDPR, incluindo acesso, retificação e exclusão
          dos dados.
        </li>
      </ul>

      <h2>2. Coleta e Utilização de Dados</h2>
      <h3>2.1 Tipos de Dados Coletados</h3>
      <p>
        A plataforma OCA BOOKS coleta diferentes tipos de dados para
        operacionalizar nossos serviços:
      </p>
      <ul>
        <li>
          <strong>Dados de Registro:</strong> Nome, e-mail, senha e outras
          informações necessárias para criar e gerenciar sua conta.
        </li>
        <li>
          <strong>Dados de Uso:</strong> Informações sobre como você interage
          com a plataforma, incluindo criação de livros, ajustes de projetos e
          preferências.
        </li>
        <li>
          <strong>Cookies e Tecnologias Similares:</strong> Informações sobre
          navegação, dispositivo utilizado e localização aproximada.
        </li>
      </ul>

      <h3>2.2 Finalidades do Uso dos Dados</h3>
      <p>Os dados coletados são utilizados para:</p>
      <ul>
        <li>
          <strong>Personalizar a experiência do usuário.</strong>
        </li>
        <li>
          <strong>Melhorar nossos serviços e funcionalidades.</strong>
        </li>
        <li>
          <strong>Fornecer suporte técnico e operacional.</strong>
        </li>
      </ul>

      <h3>2.3 Uso de Dados para Pesquisa e Desenvolvimento</h3>
      <p>
        Dados anonimizados podem ser usados para análises e desenvolvimento de
        novos recursos, sempre respeitando a privacidade.
      </p>

      <h2>3. Compartilhamento de Dados com Terceiros</h2>
      <h3>3.1 Parceiros de Serviços</h3>
      <p>Os dados podem ser compartilhados com parceiros confiáveis para:</p>
      <ul>
        <li>
          <strong>Processamento de Pagamentos:</strong> Dados são compartilhados
          com provedores de pagamento para viabilizar transações.
        </li>
        <li>
          <strong>Cumprimento de Obrigações Legais:</strong> Atendemos
          solicitações legais, como intimações e ordens judiciais.
        </li>
      </ul>

      <h3>3.2 Transferências Internacionais de Dados</h3>
      <p>
        Se necessário transferir dados para fora do Brasil, a OCA BOOKS assegura
        que os mesmos níveis de proteção sejam aplicados, conforme exigido pela
        LGPD.
      </p>

      <h2>4. Armazenamento e Segurança dos Dados</h2>
      <h3>4.1 Medidas de Segurança</h3>
      <p>
        A OCA BOOKS implementa medidas como criptografia, autenticação em dois
        fatores e controle de acesso para proteger seus dados contra acessos não
        autorizados.
      </p>

      <h3>4.2 Período de Retenção de Dados</h3>
      <p>
        Os dados serão armazenados enquanto sua conta estiver ativa ou conforme
        necessário para cumprir obrigações legais.
      </p>

      <h2>5. Direitos dos Usuários</h2>
      <h3>
        5.1 Acesso, Correção, Exclusão de Dados, Portabilidade e Revogação
      </h3>
      <p>Os usuários têm os seguintes direitos:</p>
      <ul>
        <li>
          <strong>Acesso e Retificação:</strong> Solicitar acesso aos dados
          armazenados e corrigir Informationen incorretas.
        </li>
        <li>
          <strong>Eliminação:</strong> Solicitar a exclusão de dados pessoais,
          desde que não haja base legal para retenção.
        </li>
        <li>
          <strong>Portabilidadead:</strong> Receber dados em formato estruturado
          e transmitir a outro controlador.
        </li>
        <li>
          <strong>Revogação de Consentimento:</strong> Retirar consentimento
          para tratamento de dados a qualquer momento.
        </li>
      </ul>

      <p>
        Reclamações podem ser encaminhadas à OCA BOOKS ou às autoridades
        competentes.
      </p>

      <h2>6. Alterações na Política de Privacidade</h2>
      <h3>6.1 Notificação de Alterações</h3>
      <p>
        Alterações significativas serão comunicadas por e-mail ou notificação na
        plataforma.
      </p>

      <h3>6.2 Consentimento para Mudanças</h3>
      <p>
        O uso continuado dos serviços implica aceitação das atualizações desta
        política.
      </p>

      <p>
        Para dúvidas ou solicitações, entre em contato pelo e-mail:{" "}
        <a href="mailto:suporte@ocabooks.com">suporte@ocabooks.com</a> ou
        diretamente pelo canal de suporte na plataforma.
      </p>
    </Flex>
  );
}
