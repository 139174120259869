import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { ContainerSmall } from "../../../components/ContainerSmall";
import { api } from "../../../service/api";
import { Input } from "../../../components/form/input";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import { InputMask } from "../../../components/form/inputMask";
import { useZipCode } from "../../../service/zipCodeService";
import { AllLoading } from "../../../components/allLoading";

type createFormData = {
  first_name: string;
  surname: string;
  email: string;
  document: string;
  cellphone: string;
  cep: string;
  public_place: string;
  neighborhood: string;
  number: string;
  city: string;
  state: string;
};

const createFormDataSchema = yup.object().shape({
  first_name: yup.string().required("Campo obrigatório."),
  surname: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Ccampo obrigatório.")
    .email("Email não é valido"),
  document: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
  cep: yup.string().required("Campo obrigatório."),
  public_place: yup.string().required("Campo obrigatório."),
  neighborhood: yup.string().required("Campo obrigatório."),
  number: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  state: yup.string().required("Campo obrigatório."),
});

export function AccountMe() {
  const toast = useToast();

  const { getZipCode, isLoading } = useZipCode();

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  async function getAddress(zipeCodeValue: string) {
    try {
      const data = await getZipCode(zipeCodeValue);
      setValue("city", data.localidade ?? "");
      setValue("state", data.uf ?? "");
      setValue("public_place", data.logradouro ?? "");
      setValue("neighborhood", data.bairro ?? "");
    } catch (error) {}
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.put("user/update", value);

      toast({
        position: "top-right",
        title: "Minha conta",
        description: "Conta atualizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (user.id) {
      setValue("first_name", user.first_name);
      setValue("surname", user.surname);
      setValue("email", user.email);
      setValue("cellphone", user.cellphone ?? "");
      setValue("document", user.document ?? "");
      setValue("cep", user.cep ?? "");
      setValue("public_place", user.public_place ?? "");
      setValue("neighborhood", user.neighborhood ?? "");
      setValue("number", user.number ?? "");
      setValue("city", user.city ?? "");
      setValue("state", user.state ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <ContainerSmall>
      {isLoading && <AllLoading />}
      <Flex flexDirection="column" width="100%">
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex flexDirection="column" width={["100%", "100%", "600px"]}>
            <Text style={{ fontWeight: 500, fontSize: 24 }} color="#181818">
              Minha conta
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Nome"
                placeholder="Seu nome"
                error={errors.first_name}
                {...register("first_name")}
              />
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Sobrenome"
                  placeholder="Seu último nome"
                  error={errors.surname}
                  {...register("surname")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Input
                  isDisabled
                  label="Endereço de email"
                  placeholder="seu@email.com"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  mask="999.999.999-99"
                  label="CPF"
                  placeholder="000.000.000-00"
                  error={errors.document}
                  {...register("document")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  mask="(99)99999-9999"
                  label="Telefone"
                  placeholder="(99)99999-9999"
                  error={errors.cellphone}
                  {...register("cellphone")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  mask="99999-999"
                  label="CEP"
                  placeholder="99999-999"
                  error={errors.cep}
                  {...register("cep")}
                  onBlur={(e) => {
                    let zipecodeValueName = e.currentTarget.value;
                    if (zipecodeValueName.length >= 8) {
                      getAddress(zipecodeValueName);
                    }
                  }}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Input
                  size="md"
                  label="Rua:"
                  placeholder="Informe o endereço"
                  {...register("public_place")}
                  error={errors.public_place}
                />
              </Box>

              <Flex
                gap={2}
                style={{ marginTop: 10 }}
                flexDirection={["column", "column", "row"]}
              >
                <Input
                  size="md"
                  label="Município:"
                  placeholder="Informe o Município"
                  {...register("city")}
                  error={errors.city}
                />
                <Input
                  size="md"
                  label="UF:"
                  placeholder="UF"
                  textAlign="center"
                  {...register("state")}
                  error={errors.state}
                />
              </Flex>

              <Flex
                gap={2}
                style={{ marginTop: 10 }}
                flexDirection={["column", "column", "row"]}
              >
                <Input
                  size="md"
                  label="Número:"
                  placeholder="Informe o número"
                  {...register("number")}
                  error={errors.number}
                />
                <Input
                  size="md"
                  label="Bairro:"
                  placeholder="UF"
                  {...register("neighborhood")}
                  error={errors.neighborhood}
                />
              </Flex>
              <Box>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  bg="blue.400"
                  color="white"
                  style={{
                    borderRadius: 10,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  _hover={{
                    bg: "blue.400",
                  }}
                  mt="6"
                  size="lg"
                >
                  Atualizar
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ContainerSmall>
  );
}
