import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useGoogleLogin } from "@react-oauth/google";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";

import { Navigate, useNavigate } from "react-router-dom";
import { InputPassword } from "../../../components/form/inputPassword";
import { useAuth } from "../../../hooks/useAuth";
import { IMAGE_LOGIN, LOGO, LOGO_BRANCA } from "../../../assets";
import { useState } from "react";
import { RiGoogleFill } from "react-icons/ri";
import { useGetUserInfoGoogle } from "../../../service/getUserInfoGoogle";
import {
  ModalContract,
  typeContractValue,
} from "../registerAccount/modal/modal-contract";

type SignInFormData = {
  id_google?: string;
  first_name?: string;
  surname?: string;
  email: string;
  password: string;
};

const signInFormSchema = yup.object().shape({
  id_google: yup.string().optional(),
  first_name: yup.string().optional(),
  surname: yup.string().optional(),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  password: yup.string().required("Senha é obrigatório."),
});

export function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const { signIn, isAuthenticated, isAtuhLoading } = useAuth();

  const { getUserInfoGoogle } = useGetUserInfoGoogle();

  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const [isCheckToken, setIsCheckToken] = useState(false);

  const [openModalTermPolicy, setOpenModalTermPolicy] = useState(false);
  const [typeContract, setTypeContract] = useState<typeContractValue>("empty");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(signInFormSchema) });

  const login = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (tokenResponse) => {
      setIsLoadingUser(true);
      const response = await getUserInfoGoogle(tokenResponse.access_token);
      await sendLogin({
        email: response.email,
        password: response.id,
        id_google: response.id,
        first_name: response.given_name,
        surname: response.family_name,
      });
    },
  });

  if (isAtuhLoading) return;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSignIn: SubmitHandler<SignInFormData> = async (value) => {
    await sendLogin(value);
  };

  const sendLogin = async (value: SignInFormData) => {
    try {
      await signIn({ ...value, isCreateToken: isCheckToken });
      navigate("/home");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Login",
        description: "Não foi possível realizar o login.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoadingUser(false);
    }
  };

  return (
    <>
      <ModalContract
        typeContract={typeContract}
        is_open={openModalTermPolicy}
        onClose={() => {
          setOpenModalTermPolicy(false);
        }}
      />

      <Flex
        w="100%"
        h="100vh"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          style={{ height: "100vh" }}
          flexDirection="column"
          bg="white"
          w={["100%", "100%", "55%"]}
          paddingLeft={["20px", "20px", "0px"]}
          paddingRight={["20px", "20px", "0px"]}
        >
          <Flex
            style={{ marginTop: "64px" }}
            margin={["auto", "auto", "auto"]}
            marginLeft={["auto", "0px", "70px"]}
          >
            <Image src={LOGO} style={{ width: "226px" }} alt="logo" />
          </Flex>

          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            as="form"
            onSubmit={handleSubmit(handleSignIn)}
            width="100%"
            flexDirection="column"
          >
            <Button
              width="100%"
              maxWidth="450px"
              isLoading={isSubmitting || isLoadingUser}
              type="button"
              bg="white"
              borderColor="blue.400"
              borderWidth="1px"
              color="black"
              style={{
                borderRadius: 10,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
              }}
              _hover={{
                bg: "#f6f6f6",
              }}
              mt="-100px"
              mb="30px"
              size="lg"
              onClick={() => {
                login();
              }}
              leftIcon={<Icon as={RiGoogleFill} color="black" />}
            >
              Entrar com Google
            </Button>
            <Flex
              borderTopWidth="1px"
              paddingTop="30px"
              flexDirection="column"
              style={{ maxWidth: "450px", width: "100%" }}
              height={["auto", "auto", "400px"]}
            >
              <Text style={{ fontWeight: 500, fontSize: 24 }} color="gray.200">
                Entrar
              </Text>
              <Flex flexDirection="column" style={{ marginTop: 26 }}>
                <Input
                  label="Endereço de email"
                  placeholder="seu@email.com"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
                <Box marginTop="20px">
                  <InputPassword
                    label="Senha"
                    type="password"
                    style={{ fontSize: 14 }}
                    placeholder="********************"
                    {...register("password")}
                    error={errors.password}
                  />
                </Box>

                <Stack
                  align="center"
                  justifyContent="space-between"
                  direction="row"
                  style={{ marginTop: 21 }}
                >
                  <Stack align="center" direction="row">
                    <Checkbox
                      value="check_value"
                      color="gray.200"
                      fill="#ccc"
                      borderColor="#ccc"
                      colorScheme="green"
                      onChange={(e) => {
                        setIsCheckToken(e.target.checked);
                      }}
                    >
                      Lembrar login
                    </Checkbox>
                  </Stack>

                  <Link
                    href="/recuperacao-senha"
                    color="blue.400"
                    style={{ fontWeight: 400, fontSize: 14 }}
                  >
                    Esqueceu sua senha?
                  </Link>
                </Stack>

                <Button
                  isLoading={isSubmitting || isLoadingUser}
                  type="submit"
                  bg="blue.400"
                  color="white"
                  style={{
                    borderRadius: 10,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  _hover={{
                    bg: "blue.400",
                  }}
                  mt="6"
                  size="lg"
                >
                  Entrar
                </Button>

                <Stack
                  align="center"
                  justifyContent="center"
                  direction="row"
                  style={{ marginTop: 21 }}
                  flexDirection={["column", "column", "row"]}
                >
                  <Text
                    style={{ fontWeight: 400, fontSize: 16 }}
                    color="#1E1E1E"
                  >
                    Ainda nao tem sua conta?
                  </Text>

                  <Link
                    href="/cadastro"
                    color="blue.400"
                    style={{ fontWeight: 400, fontSize: 16 }}
                  >
                    Cadastre-se agora
                  </Link>
                </Stack>

                <Flex align="center" justifyContent="center" mt="20px">
                  {/* <div class="check_terms">
                <input class="form-check-input" required type="checkbox" name="terms_and_prolicy" />
                <span>
                Li e concordo com <a target="_bleank" href="/termo-de-uso">Termos de Uso</a> e <a target="_bleank" href="/politica-privacidade">Política de Privacidade.</a>
                </span>
            </div> */}
                  <Text>
                    <Link
                      color="blue.400"
                      onClick={() => {
                        setTypeContract("term");
                        setOpenModalTermPolicy(true);
                      }}
                    >
                      Termos de Uso
                    </Link>
                    {" e "}
                    <Link
                      color="blue.400"
                      onClick={() => {
                        setTypeContract("policy");
                        setOpenModalTermPolicy(true);
                      }}
                    >
                      Política de Privacidade.
                    </Link>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          style={{ height: "100vh", width: "45%" }}
          display={["none", "none", "flex"]}
          bg="white"
          position="relative"
        >
          <Image
            src={IMAGE_LOGIN}
            style={{ width: "100%", objectFit: "cover" }}
            alt="imagem robo"
          />
          <Flex
            position="absolute"
            top="38%"
            left="30%"
            width="396px"
            height="263px"
          >
            <Image
              src={LOGO_BRANCA}
              style={{ width: "396px", height: "263px" }}
              alt="logo"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
