import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiBook2Fill,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ButtomNewChapterOrPage } from "../buttomNewChapter";
import { ModalNewChapter } from "../modalNewChapter";
import { useEffect, useState } from "react";
import { ModalNewPage } from "../modalNewPage";
import { api } from "../../../../../service/api";
import { ModalPagePDF } from "../../../../../components/modalPagePDF";
import { useCheckMenu } from "../../hooks/useCheckMenu";
import { X } from "lucide-react";
import { useBiographyContext } from "../../../../../hooks/contexts/biography/biography.context";
import { BiographySelectOptionMenu } from "../../../components/selectOptionMenu";

const linksMenu = [
  {
    id: 1,
    title: "Capa",
    type: "cover",
  },
  {
    id: 2,
    title: "Título do livro",
    type: "title_ebook",
  },
  {
    id: 3,
    title: "Sinopse",
    type: "synopsis",
  },
  {
    id: 4,
    title: "Sumário",
    type: "summary",
  },
];

type Props = {
  id: string;
  pageType: string;
  pageChapter: string;
  pageId: string;
};

export function BookDataMenu({ id, pageType, pageChapter, pageId }: Props) {
  const navigate = useNavigate();

  const { dataMenuBook, getMenuBook } = useBiographyContext();

  const { statusPage, refetch: refetchStatusPage } = useCheckMenu();

  const [isOpenChapter, setIsOpenChapter] = useState(false);
  const [isOpenPage, setIsOpenpage] = useState(false);
  const [isGeneretePDF, setIsGeneretePDF] = useState(false);
  const [linkPdf, setLinkPdf] = useState("");
  const [isOpenPDF, setIsOpenPDF] = useState(false);

  const {
    isOpen: isOpenDraw,
    onOpen: openDraw,
    onClose: onCloseDraw,
  } = useDisclosure();

  async function generetePDF() {
    try {
      setIsGeneretePDF(true);
      const response = await api.post("book/page/generete", {
        biography_id: id,
      });

      setLinkPdf(response.data.path);
      if (response.data.path) {
        setIsOpenPDF(true);
        onCloseDraw();
      }
    } catch (error) {
      alert(
        "Para gerar o livro é necessário criar capa, sinopse, sumário e pagina."
      );
    } finally {
      setIsGeneretePDF(false);
    }
  }

  useEffect(() => {
    getMenuBook({
      id,
      category_name: "chapter",
    });
    refetchStatusPage({ biography_id: id, category_name: "synopsis" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <ModalPagePDF
        isPrint={false}
        link_pdf={linkPdf}
        is_open={isOpenPDF}
        onClose={() => {
          setIsOpenPDF(false);
        }}
      />
      <ModalNewChapter
        biography_id={id}
        is_open={isOpenChapter}
        onClose={() => {
          setIsOpenChapter(false);
        }}
        onCloseSend={() => {
          setIsOpenChapter(false);
          getMenuBook({
            id,
            category_name: "chapter",
          });
        }}
      />

      <ModalNewPage
        book_id={pageChapter}
        is_open={isOpenPage}
        onClose={() => {
          setIsOpenpage(false);
        }}
        onCloseSend={() => {
          setIsOpenpage(false);
          getMenuBook({
            id,
            category_name: "chapter",
          });
        }}
      />

      <Flex
        position="absolute"
        width="90%"
        top="90px"
        display={["flex", "flex", "none"]}
      >
        <BiographySelectOptionMenu
          onPress={() => {
            openDraw();
          }}
          title={
            linksMenu.find((m) => m.type === pageType)?.title ??
            dataMenuBook?.find((it) => it.id === pageChapter)?.title ??
            ""
          }
        />
      </Flex>

      <Drawer
        placement={"right"}
        size={"full"}
        onClose={onCloseDraw}
        isOpen={isOpenDraw}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <IconButton
              mt="10px"
              aria-label="close menu"
              onClick={onCloseDraw}
              display={["flex", "flex", "none"]}
              variant="outline"
              icon={<X />}
            />
          </DrawerHeader>
          <DrawerBody>
            <Flex
              padding={["0px", "0px", "30px"]}
              gap="20px"
              background="#F9F9F9"
              flexDirection="column"
            >
              {linksMenu.map((item, _) => (
                <Flex
                  key={item.type}
                  width="100%"
                  height="45px"
                  backgroundColor={
                    pageType === item.type ? "blue.400" : "transparent"
                  }
                  textDecoration={
                    statusPage !== "complete" && item.type === "summary"
                      ? "line-through"
                      : ""
                  }
                  borderRadius="5px"
                  px="10px"
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    onCloseDraw();
                    if (
                      statusPage === "complete" ||
                      item.type === "synopsis" ||
                      item.type === "cover" ||
                      item.type === "title_ebook"
                    ) {
                      navigate(`/biografia/meu-livro/${id}?type=${item.type}`);
                    }
                  }}
                >
                  <Text
                    color={pageType === item.type ? "white" : "black"}
                    fontWeight="bold"
                  >
                    {item.title}
                  </Text>
                  <Icon
                    as={RiArrowRightSLine}
                    fontSize="18px"
                    color={pageType === item.type ? "white" : "black"}
                  />
                </Flex>
              ))}

              <Accordion
                allowToggle
                index={pageType !== "chapter" ? -1 : undefined}
              >
                {dataMenuBook &&
                  dataMenuBook.map((item, _) => (
                    <AccordionItem
                      key={item.id}
                      width="100%"
                      border="none"
                      marginBottom="20px"
                      textDecoration={
                        item.status === "created" ? "line-through" : ""
                      }
                      isDisabled={item.status === "created" ? true : false}
                    >
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            borderRadius="5px"
                            backgroundColor={
                              isExpanded || pageChapter === item.id
                                ? "blue.400"
                                : "transparent"
                            }
                            padding="10px"
                            _hover={{
                              backgroundColor: "none",
                              color: "white",
                            }}
                            onClick={() => {
                              onCloseDraw();
                              navigate(
                                `/biografia/meu-livro/${id}?type=chapter&chapter=${item.id}&page=empty`
                              );
                            }}
                          >
                            <Box flex="1" textAlign="left">
                              <Text
                                color={
                                  isExpanded || pageChapter === item.id
                                    ? "white"
                                    : "black"
                                }
                                fontWeight="bold"
                              >
                                {item.title}
                              </Text>
                            </Box>
                            {isExpanded ? (
                              <Icon
                                as={RiArrowDownSLine}
                                fontSize="18px"
                                color={"white"}
                              />
                            ) : (
                              <Icon
                                as={RiArrowRightSLine}
                                fontSize="18px"
                                color={"black"}
                              />
                            )}
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            {item.pages.map((itemPage, index) => (
                              <Flex
                                marginBottom="10px"
                                width="100%"
                                height="45px"
                                backgroundColor={"transparent"}
                                borderRadius="10px"
                                borderWidth="1px"
                                borderColor={
                                  itemPage.id === pageId
                                    ? "#36A9E1"
                                    : "transparent"
                                }
                                px="10px"
                                alignItems="center"
                                justifyContent="space-between"
                                cursor={
                                  itemPage.status === "created"
                                    ? "no-drop"
                                    : "pointer"
                                }
                                _hover={{
                                  borderWidth: 1,
                                  borderColor:
                                    itemPage.status === "created"
                                      ? "transparent"
                                      : "#36A9E1",
                                }}
                                onClick={() => {
                                  if (itemPage.status !== "created") {
                                    onCloseDraw();
                                    navigate(
                                      `/biografia/meu-livro/${id}?type=chapter&chapter=${item.id}&page=${itemPage.id}`
                                    );
                                  }
                                }}
                              >
                                <Text
                                  color={
                                    itemPage.status === "created"
                                      ? "gray.400"
                                      : "black"
                                  }
                                >{`Página ${index + 1}`}</Text>
                              </Flex>
                            ))}

                            {/* <Flex width="100%" mt="10px" flexDirection="column">
                      <ButtomNewChapterOrPage
                        title="Nova Pagina"
                        onPress={() => {
                          setIsOpenpage(true);
                        }}
                      />
                    </Flex> */}
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Accordion>

              {/* <Flex width="100%" mt="10px" flexDirection="column">
          {!loading && (
            <ButtomNewChapterOrPage
              title="Novo capitulo"
              onPress={() => {
                setIsOpenChapter(true);
              }}
            />
          )}

          {loading && (
            <Center>
              <CircularProgress color="blue.400" isIndeterminate />
            </Center>
          )}
        </Flex> */}

              <Flex width="100%" mt="30px" flexDirection="column">
                {!isGeneretePDF && dataMenuBook && dataMenuBook.length > 0 && (
                  <ButtomNewChapterOrPage
                    title="Prévia do Livro"
                    isBtnBook
                    icon={RiBook2Fill}
                    onPress={() => {
                      generetePDF();
                    }}
                  />
                )}

                {isGeneretePDF && (
                  <Center>
                    <CircularProgress color="blue.400" isIndeterminate />
                  </Center>
                )}
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Flex
        display={["none", "none", "flex"]}
        padding={["0px", "0px", "30px"]}
        gap="20px"
        background="#F9F9F9"
        flexDirection="column"
      >
        {linksMenu.map((item, _) => (
          <Flex
            key={item.type}
            width="318px"
            height="45px"
            backgroundColor={
              pageType === item.type ? "blue.400" : "transparent"
            }
            textDecoration={
              statusPage !== "complete" && item.type === "summary"
                ? "line-through"
                : ""
            }
            borderRadius="5px"
            px="10px"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => {
              if (
                statusPage === "complete" ||
                item.type === "synopsis" ||
                item.type === "title_ebook"
              ) {
                navigate(`/biografia/meu-livro/${id}?type=${item.type}`);
              }
            }}
          >
            <Text
              color={pageType === item.type ? "white" : "black"}
              fontWeight="bold"
            >
              {item.title}
            </Text>
            <Icon
              as={RiArrowRightSLine}
              fontSize="18px"
              color={pageType === item.type ? "white" : "black"}
            />
          </Flex>
        ))}

        <Accordion allowToggle index={pageType !== "chapter" ? -1 : undefined}>
          {dataMenuBook &&
            dataMenuBook.map((item, _) => (
              <AccordionItem
                key={item.id}
                width="318px"
                border="none"
                marginBottom="20px"
                textDecoration={item.status === "created" ? "line-through" : ""}
                isDisabled={item.status === "created" ? true : false}
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      borderRadius="5px"
                      backgroundColor={
                        isExpanded || pageChapter === item.id
                          ? "blue.400"
                          : "transparent"
                      }
                      padding="10px"
                      _hover={{
                        backgroundColor: "none",
                        color: "white",
                      }}
                      onClick={() => {
                        navigate(
                          `/biografia/meu-livro/${id}?type=chapter&chapter=${item.id}&page=empty`
                        );
                      }}
                    >
                      <Box flex="1" textAlign="left">
                        <Text
                          color={
                            isExpanded || pageChapter === item.id
                              ? "white"
                              : "black"
                          }
                          fontWeight="bold"
                        >
                          {item.title}
                        </Text>
                      </Box>
                      {isExpanded ? (
                        <Icon
                          as={RiArrowDownSLine}
                          fontSize="18px"
                          color={"white"}
                        />
                      ) : (
                        <Icon
                          as={RiArrowRightSLine}
                          fontSize="18px"
                          color={"black"}
                        />
                      )}
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {item.pages.map((itemPage, index) => (
                        <Flex
                          marginBottom="10px"
                          width="100%"
                          height="45px"
                          backgroundColor={"transparent"}
                          borderRadius="10px"
                          borderWidth="1px"
                          borderColor={
                            itemPage.id === pageId ? "#36A9E1" : "transparent"
                          }
                          px="10px"
                          alignItems="center"
                          justifyContent="space-between"
                          cursor={
                            itemPage.status === "created"
                              ? "no-drop"
                              : "pointer"
                          }
                          _hover={{
                            borderWidth: 1,
                            borderColor:
                              itemPage.status === "created"
                                ? "transparent"
                                : "#36A9E1",
                          }}
                          onClick={() => {
                            if (itemPage.status !== "created") {
                              navigate(
                                `/biografia/meu-livro/${id}?type=chapter&chapter=${item.id}&page=${itemPage.id}`
                              );
                            }
                          }}
                        >
                          <Text
                            color={
                              itemPage.status === "created"
                                ? "gray.400"
                                : "black"
                            }
                          >{`Página ${index + 1}`}</Text>
                        </Flex>
                      ))}

                      {/* <Flex width="100%" mt="10px" flexDirection="column">
                      <ButtomNewChapterOrPage
                        title="Nova Pagina"
                        onPress={() => {
                          setIsOpenpage(true);
                        }}
                      />
                    </Flex> */}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
        </Accordion>

        {/* <Flex width="100%" mt="10px" flexDirection="column">
          {!loading && (
            <ButtomNewChapterOrPage
              title="Novo capitulo"
              onPress={() => {
                setIsOpenChapter(true);
              }}
            />
          )}

          {loading && (
            <Center>
              <CircularProgress color="blue.400" isIndeterminate />
            </Center>
          )}
        </Flex> */}

        <Flex width="100%" mt="30px" flexDirection="column">
          {!isGeneretePDF && dataMenuBook && dataMenuBook.length > 0 && (
            <ButtomNewChapterOrPage
              title="Prévia do Livro"
              isBtnBook
              icon={RiBook2Fill}
              onPress={() => {
                generetePDF();
              }}
            />
          )}

          {isGeneretePDF && (
            <Center>
              <CircularProgress color="blue.400" isIndeterminate />
            </Center>
          )}
        </Flex>
      </Flex>
    </>
  );
}
